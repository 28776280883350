
export const model_config = () => {
    return { 
        'donoghue':{
            'modelPath' : './Donoghue.glb',
            'modelPosition' : [ -9.058758885654218,53.27814161789865],
            'modelRotation' : [Math.PI / 2,180*(2*(-Math.PI)/360),0],
            'modelAltitude' : 0,
            'modelScale'    :1.0
        },
      

       'donoghue_lobby':{
            'modelPath' : './Donoghue_lobby.glb',
            'modelPosition': [ -9.058812040810576,53.27766765421712],
            'modelRotation' : [Math.PI / 2,75*(2*(-Math.PI)/360),0],
            'modelAltitude' : 0,
            'modelScale' : 1.0
        },
        'footpaths':{
            'modelPath' : './footpaths.glb',
            'modelPosition': [ -9.0653395,53.279795],
            'modelRotation' : [Math.PI / 2,-15*(2*(-Math.PI)/360),0],
            'modelAltitude' : 0,
            'modelScale' : 1.0
        }
}
}