export const config = () => {
  return { 
    "Land Use":{
      "source":"./LANDUSE.geojson",
      "config":{
    'id': 'Land Use',
    'metadata':{'desc':'Land Use', 'layer_group':'Land Use' },
    'type': 'fill-extrusion',
    'source': 'Land Use',
    'layout': {
        // Make the layer visible by default.
        'visibility': 'visible'
    },
    'paint': {
    // Get the `fill-extrusion-color` from the source `color` property.
    'fill-extrusion-height': [
      'case',
      ['==', ['get', 'LEVEL_2_VALUE'], "Lakes and Ponds"], 0.1,
      ['==', ['get', 'LEVEL_2_VALUE'], "Improved Grassland"], 0.1,
      ['==', ['get', 'LEVEL_2_VALUE'], "Treelines"], 10,
      ['==', ['get', 'LEVEL_2_VALUE'], "Other Artificial Surfaces"], 0.1,
      ['==', ['get', 'LEVEL_2_VALUE'], "Broadleaved Forest and Woodland"], 10,
      ['==', ['get', 'LEVEL_2_VALUE'], "Scrub"], 2,
      ['==', ['get', 'LEVEL_2_VALUE'], "Hedgerows"], 3,
      ['==', ['get', 'LEVEL_2_VALUE'], "Amenity Grassland"], 0.1,
      ['==', ['get', 'LEVEL_2_VALUE'], "Buildings"], 0.1,
      ['==', ['get', 'LEVEL_2_VALUE'], "Ways"], 0.1,
      ['==', ['get', 'LEVEL_2_VALUE'], "Wet Grassland"], 0.1,
      ['==', ['get', 'LEVEL_2_VALUE'], "Dry Grassland"], 0.1,
      ['==', ['get', 'LEVEL_2_VALUE'], "Cultivated Land"], 0.1,
      ['==', ['get', 'LEVEL_2_VALUE'], "Exposed Rock and Sediments"], 0.1,
      ['==', ['get', 'LEVEL_2_VALUE'], "Coastal Sediments"], 0.1,
      ['==', ['get', 'LEVEL_2_VALUE'], "Mudflats"], 0.1,
      ['==', ['get', 'LEVEL_2_VALUE'], "Rivers and Streams"], 0.1,
      ['==', ['get', 'LEVEL_2_VALUE'], "Mixed Forest"], 5,
      ['==', ['get', 'LEVEL_2_VALUE'], "Saltmarsh"], 0.1,
      ['==', ['get', 'LEVEL_2_VALUE'], "Blanket Bog"], 0.1,
      ['==', ['get', 'LEVEL_2_VALUE'], "Artificial Waterbodies"], 0.1,
      ['==', ['get', 'LEVEL_2_VALUE'], "Bare Peat"], 0.1,
      ['==', ['get', 'LEVEL_2_VALUE'], "Transitional Forest"], 5,
      ['==', ['get', 'LEVEL_2_VALUE'], "Sand Dunes"], 0.1,
      ['==', ['get', 'LEVEL_2_VALUE'], "Transitional Waterbodies"], 0.1,
      ['==', ['get', 'LEVEL_2_VALUE'], "Coniferous Forest"], 0.1,
      ['==', ['get', 'LEVEL_2_VALUE'], "Marine Water"], 0.1,
      0 // Default value if none of the conditions match
    ],
  


    'fill-extrusion-color': ['case',
    ['==', ['get', 'LEVEL_2_VALUE'], "Lakes and Ponds"], "lightblue",
    ['==', ['get', 'LEVEL_2_VALUE'], "Improved Grassland"], "green",
    ['==', ['get', 'LEVEL_2_VALUE'], "Treelines"], "darkgreen",
    ['==', ['get', 'LEVEL_2_VALUE'], "Other Artificial Surfaces"], "lightgrey",
    ['==', ['get', 'LEVEL_2_VALUE'], "Broadleaved Forest and Woodland"], "darkgreen",
    ['==', ['get', 'LEVEL_2_VALUE'], "Scrub"], "darkgreen",
    ['==', ['get', 'LEVEL_2_VALUE'], "Hedgerows"], "darkgreen",
    ['==', ['get', 'LEVEL_2_VALUE'], "Amenity Grassland"], "green",
    ['==', ['get', 'LEVEL_2_VALUE'], "Buildings"], "grey",
    ['==', ['get', 'LEVEL_2_VALUE'], "Ways"], "black",
    ['==', ['get', 'LEVEL_2_VALUE'], "Wet Grassland"], "green",
    ['==', ['get', 'LEVEL_2_VALUE'], "Dry Grassland"], "green",
    ['==', ['get', 'LEVEL_2_VALUE'], "Cultivated Land"], "green",
    ['==', ['get', 'LEVEL_2_VALUE'], "Exposed Rock and Sediments"], "yellow",
    ['==', ['get', 'LEVEL_2_VALUE'], "Coastal Sediments"], "yellow",
    ['==', ['get', 'LEVEL_2_VALUE'], "Mudflats"], "chocolate",
    ['==', ['get', 'LEVEL_2_VALUE'], "Rivers and Streams"], "lightblue",
    ['==', ['get', 'LEVEL_2_VALUE'], "Mixed Forest"], "darkgreen",
    ['==', ['get', 'LEVEL_2_VALUE'], "Saltmarsh"], "chocolate",
    ['==', ['get', 'LEVEL_2_VALUE'], "Blanket Bog"],"chocolate",
    ['==', ['get', 'LEVEL_2_VALUE'], "Artificial Waterbodies"], "lightblue",
    ['==', ['get', 'LEVEL_2_VALUE'], "Bare Peat"], "chocolate",
    ['==', ['get', 'LEVEL_2_VALUE'], "Transitional Forest"], "darkgreen",
    ['==', ['get', 'LEVEL_2_VALUE'], "Sand Dunes"], "yellow",
    ['==', ['get', 'LEVEL_2_VALUE'], "Transitional Waterbodies"], "lightblue",
    ['==', ['get', 'LEVEL_2_VALUE'], "Coniferous Forest"], "darkgreen",
    ['==', ['get', 'LEVEL_2_VALUE'], "Marine Water"], "lightblue",
    "grey" // Default value if none of the conditions match
  ],
  'fill-extrusion-base': 0,
    // Make extrusions slightly opaque to see through indoor walls.
    'fill-extrusion-opacity': 1

    },
    'minzoom': 15 // Minimum zoom level for visibility
}},

"Wood":{
  "source":"./wood.geojson",
  "config":{
  'id': 'Wood',
  'metadata':{'desc':'Wood','layer_group':"Open Street Map"},
  'type': 'fill-extrusion',
  'source': 'Wood',
  'layout': {
      // Make the layer visible by default.
      'visibility': 'none'
  },
  'paint': {
  // Get the `fill-extrusion-color` from the source `color` property.
  'fill-extrusion-color': 'darkgreen',

  // Get `fill-extrusion-height` from the source `height` property.
  'fill-extrusion-height': 10,
  'fill-extrusion-base': 0,
  // Make extrusions slightly opaque to see through indoor walls.
  'fill-extrusion-opacity': 1
  
  },
  'minzoom': 10 // Minimum zoom level for visibility
}},

"development_area":{
  "source":"./developement_area.geojson",
  "config":{
  'id': 'development_area',
  'metadata':{'desc':'Development Areas','layer_group':'Development Areas'},
  'type': 'fill-extrusion',
  'source': 'development_area',
  'layout': {
      // Make the layer visible by default.
      'visibility': 'visible'
  },
  'paint': {
  // Get the `fill-extrusion-color` from the source `color` property.
  'fill-extrusion-color': 'Red',

  // Get `fill-extrusion-height` from the source `height` property.
  'fill-extrusion-height': 50,
  'fill-extrusion-base': 0,
  // Make extrusions slightly opaque to see through indoor walls.
  'fill-extrusion-opacity': 0.5
  },
  'minzoom': 10 // Minimum zoom level for visibility
}},

/*"Accessibility":{
  "source":"./access_report.geojson",
  "config":{
  'id': 'Accessibility',
  'metadata':{'desc':'Accessibility','layer_group':'Accessibility'},
  'type': 'circle',
  'source': 'Accessibility',
  'layout': {
      // Make the layer visible by default.
      'visibility': 'visible'
  },
  'paint': {
  // Get the `fill-extrusion-color` from the source `color` property.
  'circle-color':["interpolate",["linear"],['to-number',["get",'Supermarket_Distance']],250,"black",500,"royalblue",1000,"cyan",1500,"lime",2000,"yellow",2500,"red"],
  'circle-radius':5,
  },  
  'minzoom': 10 // Minimum zoom level for visibility
}},*/

'Building Part':{
  "source":"./building_part.geojson",
  "config":{
  'id': 'Building Part',
  'metadata':{'desc':'Building Part','layer_group':'Buildings'},
  'type': 'fill-extrusion',
  'source': "Building Part",
  'layout': {
      // Make the layer visible by default.
      'visibility': 'visible'
  },
  'paint': {
  // Get the `fill-extrusion-color` from the source `color` property.
  'fill-extrusion-color': 'grey',

  // Get `fill-extrusion-height` from the source `height` property.
  'fill-extrusion-height':  [
    'case',
    
    ['==', ['get', 'building:levels'], null],
    5, // default value if null
    ['*', 5, ['to-number', ['get', 'building:levels'], 0]] // Scale and convert to number
  ],

  // Get `fill-extrusion-base` from the source `base_height` property.
  'fill-extrusion-base': 0,

  // Make extrusions slightly opaque to see through indoor walls.
  'fill-extrusion-opacity': 1
  },
  'minzoom': 14 // Minimum zoom level for visibility
}},

'Building':{
  "source":"./buildings_v3.geojson",
  "config":{
  'id': 'Building',
  'metadata':{'desc':'Building','layer_group':'Buildings'},
  'type': 'fill-extrusion',
  'source': 'Building',
  'layout': {
      // Make the layer visible by default.
      'visibility': 'visible'
  },
  'paint': {
  // Get the `fill-extrusion-color` from the source `color` property.
  'fill-extrusion-color': [
      'case',
        ['==',['get','Building_Status'],'OCCUPIED'],'grey',
        ['==',['get','Building_Status'],'VACANT'],'pale green',
        ['==',['get','Building_Status'],'DERELICT'],'pink',
        ['==',['get','Building_Status'],'HOLIDAY HOME'],'grey',
        ['==',['get','Building_Status'],'DEMOLISHED'],'black',
        ['==',['get','Building_Status'],'UNDER CONSTRUCTION'],'yellow',
        'grey'
      
      ],
  // Get `fill-extrusion-height` from the source `height` property.
  'fill-extrusion-height':  [
    'case',
    ['==', ['get','building'],'shed'],5,
    ['==', ['get','building'],'service'],5,
    ['==', ['get', 'building:levels'], null],10, // default value if null
    
    ['*', 5, ['to-number', ['get', 'building:levels'], 0]] // Scale and convert to number
  ],

  // Get `fill-extrusion-base` from the source `base_height` property.
  'fill-extrusion-base':0,

  // Make extrusions slightly opaque to see through indoor walls.
  'fill-extrusion-opacity': 1
  },
  'minzoom': 14 // Minimum zoom level for visibility
}},

'Building Complicated':{
  "source":"./building_complicated.geojson",
  "config":{
  'id': 'Building Complicated',
  'metadata':{'desc':'Building Complicated','layer_group':'Buildings'},
  'type': 'fill-extrusion',
  'source': 'Building Complicated',
  'layout': {
      // Make the layer visible by default.
      'visibility': 'visible'
  },
  'paint': {
  // Get the `fill-extrusion-color` from the source `color` property.
  'fill-extrusion-color': 'grey',

  // Get `fill-extrusion-height` from the source `height` property.
  'fill-extrusion-height': [
    'case',
    ['==', ['get', 'building:levels'], null],
    5, // default value if null
    ['*', 5, ['to-number', ['get', 'building:levels'], 0]] // Scale and convert to number
  ],
  // Get `fill-extrusion-base` from the source `base_height` property.
  'fill-extrusion-base': 0,

  // Make extrusions slightly opaque to see through indoor walls.
  'fill-extrusion-opacity': 1
  },
  'minzoom': 14 // Minimum zoom level for visibility
}},
'Salary and Employment':{
  "source":"./ED.geojson",
  "config":{
  'id': 'Salary and Employment',
  'metadata':{'desc':'Salary and Employment','layer_group':'Salary and Employment'},
  'type': 'fill',
  'source': 'Salary and Employment',
  'layout': {
      // Make the layer visible by default.
      'visibility': 'none'
  },
  'paint': {
  // Get the `fill-extrusion-color` from the source `color` property.
  'fill-color': "yellow",

  // Get `fill-extrusion-height` from the source `height` property.
  
  // Make extrusions slightly opaque to see through indoor walls.
  'fill-opacity': 0.3,
  'fill-outline-color':"black"

  },
  'minzoom': 5 // Minimum zoom level for visibility
}},
'Salary and Employment outline':{
  "source":"./ED.geojson",
  "config":{
  'id': 'Salary and Employment outline',
  'metadata':{'desc':'Salary and Employment outline','layer_group':'Salary and Employment'},
  'type': 'line',
  'source': 'Salary and Employment outline',
  'layout': {
      // Make the layer visible by default.
      'visibility': 'none'
  },
  'paint': {
    'line-color': '#000',
    'line-width': 3
    },    
    'minzoom': 5 // Minimum zoom level for visibility
}},


'Demographics':{
  "source":"./population_demo.geojson",
  "config":{
  'id': 'Demographics',
  'metadata':{'desc':'Demographics','layer_group':'CSO Reports 2022'},
  'type': 'fill',
  'source': 'Demographics',
  'layout': {
      // Make the layer visible by default.
      'visibility': 'none'
  },
  'paint': {
  // Get the `fill-extrusion-color` from the source `color` property.
  'fill-color':['interpolate',
 
  ['linear'],
  ['to-number', ['get', 'Average_Age']], // Replace 'Value' with the data field you're using.
  0, '#00FF00',       // Green at 0
  10, '#40FF00',      // Light Green at 10
  20, '#80FF00',      // Lighter Green at 20
  30, '#C0FF00',      // Light Yellow-Green at 30
  40, '#FFFF00',      // Yellow at 40
  50, '#FFD400',      // Light Orange at 50
  60, '#FFAA00',      // Orange at 60
  70, '#FF8000',      // Dark Orange at 70
  80, '#FF5500',      // Deep Orange at 80
  90, '#FF2A00'       // Red-Orange at 90
],

  // Get `fill-extrusion-height` from the source `height` property.
  
  // Make extrusions slightly opaque to see through indoor walls.
  'fill-opacity': 0.6,
  'fill-outline-color':"black"

  },
  'minzoom': 5// Minimum zoom level for visibility
}},


'Demographics outline':{
  "source":"./population_demo.geojson",
  "config":{
  'id': 'Demographics outline',
  'metadata':{'desc':'Demographics outline','layer_group':'CSO Reports 2022'},
  'type': 'line',
  'source': 'Demographics outline',
  'layout': {
      // Make the layer visible by default.
      'visibility': 'none'
  },
  'paint': {
    'line-color': '#000',
    'line-width': 3
    },    
  'minzoom': 5// Minimum zoom level for visibility
}},
'Employment':{
  "source":"./Employment.geojson",
  "config":{
  'id': 'Employment',
  'metadata':{'desc':'Employment Commute','layer_group':'CSO Reports 2022'},
  'type': 'fill',
  'source': 'Employment',
  'layout': {
      // Make the layer visible by default.
      'visibility': 'none'
  },
  'paint': {
  // Get the `fill-extrusion-color` from the source `color` property.
  'fill-color':['interpolate',
              ['linear'],
              ['/',['+',['to-number',['get', 'Commute by Bicycle']],['to-number',['get', 'Commute by On Foot']]],['to-number',['get','Commute by Total']]],
              0,
              '#FFFFFF', // White
              0.1,
              '#F0FFF0', // Honeydew
              0.2,
              '#D0FFD0', // Mint Green
              0.3,
              '#B0FFB0', // Pale Green
              0.4,
              '#90FF90', // Light Green
              0.5,
              '#70FF70', // Green
              0.6,
              '#50FF50', // Medium Green
              0.7,
              '#30FF30', // Lime Green
              0.8,
              '#10FF10', // Neon Green
              0.9,
              '#00FF00', // Pure Green
              1,
              '#00FF00' // Pure Green
            ],

  // Get `fill-extrusion-height` from the source `height` property.
  
  // Make extrusions slightly opaque to see through indoor walls.
  'fill-opacity': 0.5,
  'fill-outline-color':"black"

  },
  'minzoom': 5// Minimum zoom level for visibility
}},


'Employment outline':{
  "source":"./Employment.geojson",
  "config":{
  'id': 'Employment outline',
  'metadata':{'desc':'Employment Commute outline','layer_group':'CSO Reports 2022'},
  'type': 'line',
  'source': 'Employment outline',
  'layout': {
      // Make the layer visible by default.
      'visibility': 'none'
  },
  'paint': {
    'line-color': '#000',
    'line-width': 3
    },    
  'minzoom': 5// Minimum zoom level for visibility
}},


'Health':{
  "source":"./Health.geojson",
  "config":{
  'id': 'Health',
  'metadata':{'desc':'Health','layer_group':'CSO Reports 2022'},
  'type': 'fill',
  'source': 'Health',
  'layout': {
      // Make the layer visible by default.
      'visibility': 'none'
  },
  'paint': {
  // Get the `fill-extrusion-color` from the source `color` property.
  'fill-color':['interpolate',
              ['linear'],
             ['to-number',['get', 'Average_Health (1:Very Good 5:Very Bad)' ]],
             1, '#00FF00',       // Green at 1
             1.5, '#00FF00',     // Green at 1.5
             2, '#C0FF00',       // Yellow-Green at 2
             2.5, '#C0FF00',     // Yellow-Green at 2.5
             3, '#FFFF00',       // Yellow at 3
             3.5, '#FFFF00',     // Yellow at 3.5
             4, '#FFAA00',       // Orange at 4
             4.5, '#FFAA00',     // Orange at 4.5
             5, '#FF0000'  
  ],

  // Get `fill-extrusion-height` from the source `height` property.
  
  // Make extrusions slightly opaque to see through indoor walls.
  'fill-opacity': 0.5,
  'fill-outline-color':"black"

  },
  'minzoom': 5// Minimum zoom level for visibility
}},


'Health outline':{
  "source":"./Health.geojson",
  "config":{
  'id': 'Health outline',
  'metadata':{'desc':'Health outline','layer_group':'CSO Reports 2022'},
  'type': 'line',
  'source': 'Health outline',
  'layout': {
      // Make the layer visible by default.
      'visibility': 'none'
  },
  'paint': {
    'line-color': '#000',
    'line-width': 3
    },    
  'minzoom': 5// Minimum zoom level for visibility
}},

'Household':{
  "source":"./household.geojson",
  "config":{
  'id': 'Household',
  'metadata':{'desc':'Household','layer_group':'CSO Reports 2022'},
  'type': 'fill',
  'source': 'Household',
  'layout': {
      // Make the layer visible by default.
      'visibility': 'none'
  },
  'paint': {
  // Get the `fill-extrusion-color` from the source `color` property.
  'fill-color':['interpolate',
              ['linear'],
              ['/',['to-number',['get', 'Has at least one renewable energy source of any type']],['to-number',['get','All households']]],
              0,
              '#FFFFFF', // White
              0.1,
              '#F0FFF0', // Honeydew
              0.2,
              '#D0FFD0', // Mint Green
              0.3,
              '#B0FFB0', // Pale Green
              0.4,
              '#90FF90', // Light Green
              0.5,
              '#70FF70', // Green
              0.6,
              '#50FF50', // Medium Green
              0.7,
              '#30FF30', // Lime Green
              0.8,
              '#10FF10', // Neon Green
              0.9,
              '#00FF00', // Pure Green
              1,
              '#00FF00' // Pure Green
            ],
        
  // Get `fill-extrusion-height` from the source `height` property.
  
  // Make extrusions slightly opaque to see through indoor walls.
  'fill-opacity': 0.5,
  'fill-outline-color':"black"

  },
  'minzoom': 5// Minimum zoom level for visibility
  }},

  'Household outline':{
    "source":"./household.geojson",
    "config":{
    'id': 'Household outline',
    'metadata':{'desc':'Household outline','layer_group':'CSO Reports 2022'},
    'type': 'line',
    'source': 'Household outline',
    'layout': {
        // Make the layer visible by default.
        'visibility': 'none'
    },
    'paint': {
      'line-color': '#000',
      'line-width': 3
      },    
      'minzoom': 5// Minimum zoom level for visibility
  }},

    'Education':{
              "source":"./education.geojson",
              "config":{
              'id': 'Education',
              'metadata':{'desc':'Education','layer_group':'CSO Reports 2022'},
              'type': 'fill',
              'source': 'Education',
              'layout': {
                  // Make the layer visible by default.
                  'visibility': 'none'
    },
              'paint': {
              // Get the `fill-extrusion-color` from the source `color` property.
              'fill-color':'light blue',
            
   

  // Get `fill-extrusion-height` from the source `height` property.
  
  // Make extrusions slightly opaque to see through indoor walls.
  'fill-opacity': 0.5,
  'fill-outline-color':"black"

  },
  'minzoom': 5 // Minimum zoom level for visibility
}},

'Education outline':{
  "source":"./education.geojson",
  "config":{
  'id': 'Education outline',
  'metadata':{'desc':'Education outline','layer_group':'CSO Reports 2022'},
  'type': 'line',
  'source': 'Education outline',
  'layout': {
      // Make the layer visible by default.
      'visibility': 'none'
  },
  'paint': {
    'line-color': '#000',
    'line-width': 3
    },    
    'minzoom': 5// Minimum zoom level for visibility
}},

"allotments_c":{
  "source":"./allotments_c.geojson",
  "config":{
  'id': 'allotments_c',
  'metadata':{'desc':'Allotments','layer_group':"Open Street Map"},
  'type': 'fill-extrusion',
  'source': 'allotments_c',
  'layout': {
      // Make the layer visible by default.
      'visibility': 'none'
  },
  'paint': {
  // Get the `fill-extrusion-color` from the source `color` property.
  'fill-extrusion-color': 'chocolate',

  // Get `fill-extrusion-height` from the source `height` property.
  'fill-extrusion-height': 1,
  'fill-extrusion-base':0,

  // Make extrusions slightly opaque to see through indoor walls.
  'fill-extrusion-opacity': 1
  },
  'minzoom': 10 // Minimum zoom level for visibility
}},
"allotments":{
  "source":"./allotments.geojson",
  "config":{
  'id': 'allotments',
  'metadata':{'desc':'Allotments','layer_group':"Open Street Map"},
  'type': 'fill-extrusion',
  'source': 'allotments',
  'layout': {
      // Make the layer visible by default.
      'visibility': 'none'
  },
  'paint': {
  // Get the `fill-extrusion-color` from the source `color` property.
  'fill-extrusion-color': 'chocolate',

  // Get `fill-extrusion-height` from the source `height` property.
  'fill-extrusion-height': 1,
  'fill-extrusion-base':0,
  // Make extrusions slightly opaque to see through indoor walls.
  'fill-extrusion-opacity': 1
  },
  'minzoom': 10 // Minimum zoom level for visibility
}},
"beach_c":{
  "source":"./beach_c.geojson",
  "config":{
  'id': 'beach_c',
  'metadata':{'desc':'beach','layer_group':"Open Street Map"},
  'type': 'fill-extrusion',
  'source': 'beach_c',
  'layout': {
      // Make the layer visible by default.
      'visibility': 'none'
  },
  'paint': {
  // Get the `fill-extrusion-color` from the source `color` property.
  'fill-extrusion-color': 'yellow',

  // Get `fill-extrusion-height` from the source `height` property.
  'fill-extrusion-height': 1,
  'fill-extrusion-base':0,
  // Make extrusions slightly opaque to see through indoor walls.
  'fill-extrusion-opacity': 1
  },
  'minzoom': 10 // Minimum zoom level for visibility
}},

"beach":{
  "source":"./beach.geojson",
  "config":{
  'id': 'beach',
  'metadata':{'desc':'beach','layer_group':"Open Street Map"},
  'type': 'fill-extrusion',
  'source': 'beach',
  'layout': {
      // Make the layer visible by default.
      'visibility': 'none'
  },
  'paint': {
  // Get the `fill-extrusion-color` from the source `color` property.
  'fill-extrusion-color': 'yellow',

  // Get `fill-extrusion-height` from the source `height` property.
  'fill-extrusion-height': 1,
  'fill-extrusion-base':0,
  // Make extrusions slightly opaque to see through indoor walls.
  'fill-extrusion-opacity': 1
  },
  'minzoom': 10 // Minimum zoom level for visibility
}},

"BER_report":{
  "source":"./BER_report.geojson",
  "config":{
  'id': 'BER_report',
  'metadata':{'desc':'BER by SA','layer_group':'BER'},
  'type': 'fill',
  'source': 'BER_report',
  'layout': {
      // Make the layer visible by default.
      'visibility': 'none'
  },
  'paint': {
  // Get the `fill-extrusion-color` from the source `color` property.
  'fill-color':[
    'interpolate',
        ['linear'],
        ['to-number',['get', 'BER_Average (A1:1 - G:15)']],
        1,
        '#00FF00', // Green at 1
        2,
        '#40FF00', // Light Green at 2
        3,
        '#80FF00', // Lighter Green at 3
        4,
        '#C0FF00', // Light Yellow-Green at 4
        5,
        '#FFFF00', // Yellow at 5
        6,
        '#FFD400', // Light Orange at 6
        7,
        '#FFAA00', // Orange at 7
        8,
        '#FF8000', // Dark Orange at 8
        9,
        '#FF5500', // Orange-Red at 9
        10,
        '#FF2A00', // Red at 10
        11,
        '#FF0000', // Dark Red at 11
        12,
        '#D70000', // Darker Red at 12
        13,
        '#A30000', // Darker Red at 13
        14,
        '#6E0000', // Darker Red at 14
        15,
        '#3A0000' // Darkest Red at 15
          
        
   
      ],

  // Get `fill-extrusion-height` from the source `height` property.
  'fill-outline-color':'black',
  // Make extrusions slightly opaque to see through indoor walls.
  'fill-opacity': 0.5
  },
  'minzoom': 5// Minimum zoom level for visibility
}},


"breakwater":{
  "source":"./breakwater.geojson",
  "config":{
  'id': 'breakwater',
  'metadata':{'desc':'breakwater','layer_group':"Open Street Map"},
  'type': 'fill-extrusion',
  'source': 'breakwater',
  'layout': {
      // Make the layer visible by default.
      'visibility': 'none'
  },
  'paint': {
  // Get the `fill-extrusion-color` from the source `color` property.
  'fill-extrusion-color': 'darkgrey',

  // Get `fill-extrusion-height` from the source `height` property.
  'fill-extrusion-height': 1,
  'fill-extrusion-base':0,
  // Make extrusions slightly opaque to see through indoor walls.
  'fill-extrusion-opacity': 1
  },
  'minzoom': 10 // Minimum zoom level for visibility
}},

"bus_stop":{
  "source":"./bus_stop.geojson",
  "config":{
  'id': 'bus_stop',
  'metadata':{'desc':'bus stop','layer_group':"Open Street Map"},
  'type': 'fill-extrusion',
  'source': 'bus_stop',
  'layout': {
      // Make the layer visible by default.
      'visibility': 'visible'
  },
  'paint': {
  // Get the `fill-extrusion-color` from the source `color` property.
  'fill-extrusion-color': 'red',

  // Get `fill-extrusion-height` from the source `height` property.
  'fill-extrusion-height': 10,
  'fill-extrusion-base':0,
  // Make extrusions slightly opaque to see through indoor walls.
  'fill-extrusion-opacity': 1
  },
  'minzoom': 10 // Minimum zoom level for visibility
}},

"car_owners_sa":{
  "source":"./car_owners_sa.geojson",
  "config":{
  'id': 'car_owners_sa',
  'metadata':{'desc':'Car Owners SA','layer_group':'Car Owners SA'},
  'type': 'fill',
  'source': 'car_owners_sa',
  'layout': {
      // Make the layer visible by default.
      'visibility': 'none'
  },
  'paint': {
  // Get the `fill-extrusion-color` from the source `color` property.
  'fill-color':[
                        'interpolate',
                            ['linear'],
                            ['get', 'cars per person'],
                            0,
                            '#FFFFFF', // White
                            0.1,
                            '#FFE0E0', // Light Red
                            0.2,
                            '#FFC0C0', // Lighter Red
                            0.3,
                            '#FFA0A0', // Lightest Red
                            0.4,
                            '#FF8080', // Red
                            0.5,
                            '#FF6060', // Dark Red
                            0.6,
                            '#FF4040', // Darker Red
                            0.7,
                            '#FF2020', // Darkest Red
                            0.8,
                            '#FF0000', // Pure Red
                            1,
                            '#FF0000' // Pure Red
                          ],

  'fill-outline-color':'black',
  // Make extrusions slightly opaque to see through indoor walls.
  'fill-opacity': 0.5
  },
  'minzoom': 10 // Minimum zoom level for visibility
}},
"car_owners_ED":{
  "source":"./car_owners_ED.geojson",
  "config":{
  'id': 'car_owners_ED',
  'metadata':{'desc':'Car Owners ED','layer_group':'Car Owners ED'},
  'type': 'fill-extrusion',
  'source': 'car_owners_ED',
  'layout': {
      // Make the layer visible by default.
      'visibility': 'none'
  },
  'paint': {
  // Get the `fill-extrusion-color` from the source `color` property.

  'fill-extrusion-height':['*',1000,['get', 'cars per person']],
  'fill-extrusion-color':[
                        'interpolate',
                            ['linear'],
                            ['get', 'cars per person'],
                            0,
                            '#FFFFFF', // White
                            0.1,
                            '#FFE0E0', // Light Red
                            0.2,
                            '#FFC0C0', // Lighter Red
                            0.3,
                            '#FFA0A0', // Lightest Red
                            0.4,
                            '#FF8080', // Red
                            0.5,
                            '#FF6060', // Dark Red
                            0.6,
                            '#FF4040', // Darker Red
                            0.7,
                            '#FF2020', // Darkest Red
                            0.8,
                            '#FF0000', // Pure Red
                            1,
                            '#FF0000' // Pure Red
                          ],



  // Make extrusions slightly opaque to see through indoor walls.
  'fill-extrusion-opacity': 0.9
  },
  'minzoom': 10 // Minimum zoom level for visibility
}},
"cemetery":{
  "source":"./cemetery.geojson",
  "config":{
  'id': 'cemetery',
  'metadata':{'desc':'cemetery','layer_group':"Open Street Map"},
  'type': 'fill-extrusion',
  'source': 'cemetery',
  'layout': {
      // Make the layer visible by default.
      'visibility': 'none'
  },
  'paint': {
  // Get the `fill-extrusion-color` from the source `color` property.
  'fill-extrusion-color': 'green',

  // Get `fill-extrusion-height` from the source `height` property.
  'fill-extrusion-height': 1,

  // Make extrusions slightly opaque to see through indoor walls.
  'fill-extrusion-opacity': 1
  },
  'minzoom': 10 // Minimum zoom level for visibility
}},
"commercial_c":{
  "source":"./commercial_c.geojson",
  "config":{
  'id': 'commercial_c',
  'metadata':{'desc':'commercial','layer_group':"Open Street Map"},
  'type': 'fill-extrusion',
  'source': 'commercial_c',
  'layout': {
      // Make the layer visible by default.
      'visibility': 'none'
  },
  'paint': {
  // Get the `fill-extrusion-color` from the source `color` property.
  'fill-extrusion-color': 'pink',

  // Get `fill-extrusion-height` from the source `height` property.
  'fill-extrusion-height': 0.5,

  // Make extrusions slightly opaque to see through indoor walls.
  'fill-extrusion-opacity': 1
  },
  'minzoom': 10 // Minimum zoom level for visibility
}},

"commercial":{
  "source":"./commercial.geojson",
  "config":{
  'id': 'commercial',
  'metadata':{'desc':'commercial','layer_group':"Open Street Map"},
  'type': 'fill-extrusion',
  'source': 'commercial',
  'layout': {
      // Make the layer visible by default.
      'visibility': 'none'
  },
  'paint': {
  // Get the `fill-extrusion-color` from the source `color` property.
  'fill-extrusion-color': 'pink',

  // Get `fill-extrusion-height` from the source `height` property.
  'fill-extrusion-height': 0.5,

  // Make extrusions slightly opaque to see through indoor walls.
  'fill-extrusion-opacity': 1
  },
  'minzoom': 10 // Minimum zoom level for visibility
}},

"farmland_c":{
  "source":"./farmland_c.geojson",
  "config":{
  'id': 'farmland_c',
  'metadata':{'desc':'farmland','layer_group':"Open Street Map"},
  'type': 'fill-extrusion',
  'source': 'farmland_c',
  'layout': {
      // Make the layer visible by default.
      'visibility': 'none'
  },
  'paint': {
  // Get the `fill-extrusion-color` from the source `color` property.
  'fill-extrusion-color': 'green',

  // Get `fill-extrusion-height` from the source `height` property.
  'fill-extrusion-height': 1,

  // Make extrusions slightly opaque to see through indoor walls.
  'fill-extrusion-opacity': 1
  },
  'minzoom': 10 // Minimum zoom level for visibility
}},

"farmland":{
  "source":"./farmland.geojson",
  "config":{
  'id': 'farmland',
  'metadata':{'desc':'farmland','layer_group':"Open Street Map"},
  'type': 'fill-extrusion',
  'source': 'farmland',
  'layout': {
      // Make the layer visible by default.
      'visibility': 'none'
  },
  'paint': {
  // Get the `fill-extrusion-color` from the source `color` property.
  'fill-extrusion-color': 'green',

  // Get `fill-extrusion-height` from the source `height` property.
  'fill-extrusion-height': 1,
  'fill-extrusion-base':0,
 
  // Make extrusions slightly opaque to see through indoor walls.
  'fill-extrusion-opacity': 1
  },
  'minzoom': 10 // Minimum zoom level for visibility
}},

"forest_c":{
  "source":"./forest_c.geojson",
  "config":{
  'id': 'forest_c',
  'metadata':{'desc':'forest','layer_group':"Open Street Map"},
  'type': 'fill-extrusion',
  'source': 'forest_c',
  'layout': {
      // Make the layer visible by default.
      'visibility': 'none'
  },
  'paint': {
  // Get the `fill-extrusion-color` from the source `color` property.
  'fill-extrusion-color': 'darkgreen',

  // Get `fill-extrusion-height` from the source `height` property.
  'fill-extrusion-height': 10,
  'fill-extrusion-base':0,

  // Make extrusions slightly opaque to see through indoor walls.
  'fill-extrusion-opacity': 1
  },
  'minzoom': 10 // Minimum zoom level for visibility
}},

"forest":{
  "source":"./forest.geojson",
  "config":{
  'id': 'forest',
  'metadata':{'desc':'forest','layer_group':"Open Street Map"},
  'type': 'fill-extrusion',
  'source': 'forest',
  'layout': {
      // Make the layer visible by default.
      'visibility': 'none'
  },
  'paint': {
  // Get the `fill-extrusion-color` from the source `color` property.
  'fill-extrusion-color': 'darkgreen',

  // Get `fill-extrusion-height` from the source `height` property.
  'fill-extrusion-height': 10,

  // Make extrusions slightly opaque to see through indoor walls.
  'fill-extrusion-opacity': 1
  },
  'minzoom': 10 // Minimum zoom level for visibility
}},

"garden":{
  "source":"./garden.geojson",
  "config":{
  'id': 'garden',
  'metadata':{'desc':'garden','layer_group':"Open Street Map"},
  'type': 'fill-extrusion',
  'source': 'garden',
  'layout': {
      // Make the layer visible by default.
      'visibility': 'none'
  },
  'paint': {
  // Get the `fill-extrusion-color` from the source `color` property.
  'fill-extrusion-color': 'green',

  // Get `fill-extrusion-height` from the source `height` property.
  'fill-extrusion-height': 1,
  'fill-extrusion-base':0,
 
  // Make extrusions slightly opaque to see through indoor walls.
  'fill-extrusion-opacity': 1
  },
  'minzoom': 10 // Minimum zoom level for visibility
}},

"golf":{
  "source":"./golf.geojson",
  "config":{
  'id': 'golf',
  'metadata':{'desc':'golf','layer_group':"Open Street Map"},
  'type': 'fill-extrusion',
  'source': 'golf',
  'layout': {
      // Make the layer visible by default.
      'visibility': 'none'
  },
  'paint': {
  // Get the `fill-extrusion-color` from the source `color` property.
  'fill-extrusion-color': 'green',

  // Get `fill-extrusion-height` from the source `height` property.
  'fill-extrusion-height': 1,
  'fill-extrusion-base':0,

  // Make extrusions slightly opaque to see through indoor walls.
  'fill-extrusion-opacity': 1
  },
  'minzoom': 10 // Minimum zoom level for visibility
}},

"grass_c":{
  "source":"./grass_c.geojson",
  "config":{
  'id': 'grass_c',
  'metadata':{'desc':'grass','layer_group':"Open Street Map"},
  'type': 'fill-extrusion',
  'source': 'grass_c',
  'layout': {
      // Make the layer visible by default.
      'visibility': 'none'
  },
  'paint': {
  // Get the `fill-extrusion-color` from the source `color` property.
  'fill-extrusion-color': 'green',

  // Get `fill-extrusion-height` from the source `height` property.
  'fill-extrusion-height': 1,
  'fill-extrusion-base':0,

  // Make extrusions slightly opaque to see through indoor walls.
  'fill-extrusion-opacity': 1
  },
  'minzoom': 10 // Minimum zoom level for visibility
}},

"grass":{
  "source":"./grass.geojson",
  "config":{
  'id': 'grass',
  'metadata':{'desc':'grass','layer_group':"Open Street Map"},
  'type': 'fill-extrusion',
  'source': 'grass',
  'layout': {
      // Make the layer visible by default.
      'visibility': 'none'
  },
  'paint': {
  // Get the `fill-extrusion-color` from the source `color` property.
  'fill-extrusion-color': 'green',

  // Get `fill-extrusion-height` from the source `height` property.
  'fill-extrusion-height': 1,
  'fill-extrusion-base':0,

  // Make extrusions slightly opaque to see through indoor walls.
  'fill-extrusion-opacity': 1
  },
  'minzoom': 10 // Minimum zoom level for visibility
}},

"grave_yard_c":{
  "source":"./grave_yard_c.geojson",
  "config":{
  'id': 'grave_yard_c',
  'metadata':{'desc':'grave_yard','layer_group':"Open Street Map"},
  'type': 'fill-extrusion',
  'source': 'grave_yard_c',
  'layout': {
      // Make the layer visible by default.
      'visibility': 'none'
  },
  'paint': {
  // Get the `fill-extrusion-color` from the source `color` property.
  'fill-extrusion-color': 'green',

  // Get `fill-extrusion-height` from the source `height` property.
  'fill-extrusion-height': 1,
  'fill-extrusion-base':0,

  // Make extrusions slightly opaque to see through indoor walls.
  'fill-extrusion-opacity': 1
  },
  'minzoom': 10 // Minimum zoom level for visibility
}},

"grave_yard":{
  "source":"./grave_yard.geojson",
  "config":{
  'id': 'grave_yard',
  'metadata':{'desc':'grave_yard','layer_group':"Open Street Map"},
  'type': 'fill-extrusion',
  'source': 'grave_yard',
  'layout': {
      // Make the layer visible by default.
      'visibility': 'none'
  },
  'paint': {
  // Get the `fill-extrusion-color` from the source `color` property.
  'fill-extrusion-color': 'green',

  // Get `fill-extrusion-height` from the source `height` property.
  'fill-extrusion-height': 1,
  'fill-extrusion-base':0,

  // Make extrusions slightly opaque to see through indoor walls.
  'fill-extrusion-opacity': 1
  },
  'minzoom': 10 // Minimum zoom level for visibility
}},

"helipad":{
  "source":"./helipad.geojson",
  "config":{
  'id': 'helipad',
  'metadata':{'desc':'helipad','layer_group':"Open Street Map"},
  'type': 'fill-extrusion',
  'source': 'helipad',
  'layout': {
      // Make the layer visible by default.
      'visibility': 'none'
  },
  'paint': {
  // Get the `fill-extrusion-color` from the source `color` property.
  'fill-extrusion-color': 'red',

  // Get `fill-extrusion-height` from the source `height` property.
  'fill-extrusion-height': 1,
  'fill-extrusion-base':0,

  // Make extrusions slightly opaque to see through indoor walls.
  'fill-extrusion-opacity': 1
  },
  'minzoom': 10 // Minimum zoom level for visibility
}},

"hospital_c":{
  "source":"./hospital_c.geojson",
  "config":{
  'id': 'hospital_c',
  'metadata':{'desc':'hospital','layer_group':"Open Street Map"},
  'type': 'fill-extrusion',
  'source': 'hospital_c',
  'layout': {
      // Make the layer visible by default.
      'visibility': 'none'
  },
  'paint': {
  // Get the `fill-extrusion-color` from the source `color` property.
  'fill-extrusion-color': 'red',

  // Get `fill-extrusion-height` from the source `height` property.
  
  'fill-extrusion-height': 0.5,

  'fill-extrusion-base':0,
  // Make extrusions slightly opaque to see through indoor walls.
  'fill-extrusion-opacity': 1
  },
  'minzoom': 10 // Minimum zoom level for visibility
}},
"hospital":{
  "source":"./hospital.geojson",
  "config":{
  'id': 'hospital',
  'metadata':{'desc':'hospital','layer_group':"Open Street Map"},
  'type': 'fill-extrusion',
  'source': 'hospital',
  'layout': {
      // Make the layer visible by default.
      'visibility': 'none'
  },
  'paint': {
  // Get the `fill-extrusion-color` from the source `color` property.
  'fill-extrusion-color': 'red',

  // Get `fill-extrusion-height` from the source `height` property.
  'fill-extrusion-height': 0.5,
  'fill-extrusion-base':0,
  // Make extrusions slightly opaque to see through indoor walls.
  'fill-extrusion-opacity': 1
  },
  'minzoom': 10 // Minimum zoom level for visibility
}},

"industrial_c":{
  "source":"./industrial_c.geojson",
  "config":{
  'id': 'industrial_c',
  'metadata':{'desc':'industrial_c','layer_group':"Open Street Map"},
  'type': 'fill-extrusion',
  'source': 'industrial_c',
  'layout': {
      // Make the layer visible by default.
      'visibility': 'none'
  },
  'paint': {
  // Get the `fill-extrusion-color` from the source `color` property.
  'fill-extrusion-color': 'pink',

  // Get `fill-extrusion-height` from the source `height` property.
  'fill-extrusion-height': 0.5,
  'fill-extrusion-base':0,
  // Make extrusions slightly opaque to see through indoor walls.
  'fill-extrusion-opacity': 1
  },
  'minzoom': 10 // Minimum zoom level for visibility
}},

"industrial":{
  "source":"./industrial.geojson",
  "config":{
  'id': 'industrial',
  'metadata':{'desc':'industrial','layer_group':"Open Street Map"},
  'type': 'fill-extrusion',
  'source': 'industrial',
  'layout': {
      // Make the layer visible by default.
      'visibility': 'none'
  },
  'paint': {
  // Get the `fill-extrusion-color` from the source `color` property.
  'fill-extrusion-color': 'pink',

  // Get `fill-extrusion-height` from the source `height` property.
  'fill-extrusion-height': 0.5,
  'fill-extrusion-base':0,
  // Make extrusions slightly opaque to see through indoor walls.
  'fill-extrusion-opacity': 1
  },
  'minzoom': 10 // Minimum zoom level for visibility
}},

"meadow":{
  "source":"./meadow.geojson",
  "config":{
  'id': 'meadow',
  'metadata':{'desc':'meadow','layer_group':"Open Street Map"},
  'type': 'fill-extrusion',
  'source': 'meadow',
  'layout': {
      // Make the layer visible by default.
      'visibility': 'none'
  },
  'paint': {
  // Get the `fill-extrusion-color` from the source `color` property.
  'fill-extrusion-color': 'green',

  // Get `fill-extrusion-height` from the source `height` property.
  'fill-extrusion-height': 1,
  'fill-extrusion-base':0,
  // Make extrusions slightly opaque to see through indoor walls.
  'fill-extrusion-opacity': 1
  },
  'minzoom': 10 // Minimum zoom level for visibility
}},
"military_c":{
  "source":"./military_c.geojson",
  "config":{
  'id': 'military_c',
  'metadata':{'desc':'military','layer_group':"Open Street Map"},
  'type': 'fill-extrusion',
  'source': 'military_c',
  'layout': {
      // Make the layer visible by default.
      'visibility': 'none'
  },
  'paint': {
  // Get the `fill-extrusion-color` from the source `color` property.
  'fill-extrusion-color': 'orange',

  // Get `fill-extrusion-height` from the source `height` property.
  'fill-extrusion-height': 0.5,
  'fill-extrusion-base':0,
  // Make extrusions slightly opaque to see through indoor walls.
  'fill-extrusion-opacity': 1
  },
  'minzoom': 10 // Minimum zoom level for visibility
}},
"military":{
  "source":"./military.geojson",
  "config":{
  'id': 'military',
  'metadata':{'desc':'military','layer_group':"Open Street Map"},
  'type': 'fill-extrusion',
  'source': 'military',
  'layout': {
      // Make the layer visible by default.
      'visibility': 'none'
  },
  'paint': {
  // Get the `fill-extrusion-color` from the source `color` property.
  'fill-extrusion-color': 'orange',

  // Get `fill-extrusion-height` from the source `height` property.
  'fill-extrusion-height': 0.5,
  'fill-extrusion-base':0,
  // Make extrusions slightly opaque to see through indoor walls.
  'fill-extrusion-opacity': 1
  },
  'minzoom': 10 // Minimum zoom level for visibility
}},

"park_c":{
  "source":"./park_c.geojson",
  "config":{
  'id': 'park_c',
  'metadata':{'desc':'park','layer_group':"Open Street Map"},
  'type': 'fill-extrusion',
  'source': 'park_c',
  'layout': {
      // Make the layer visible by default.
      'visibility': 'none'
  },
  'paint': {
  // Get the `fill-extrusion-color` from the source `color` property.
  'fill-extrusion-color': 'green',

  // Get `fill-extrusion-height` from the source `height` property.
  'fill-extrusion-height': 0.7,
  'fill-extrusion-base':0,
  // Make extrusions slightly opaque to see through indoor walls.
  'fill-extrusion-opacity': 1
  },
  'minzoom': 10 // Minimum zoom level for visibility
}},
"park":{
  "source":"./park.geojson",
  "config":{
  'id': 'park',
  'metadata':{'desc':'park','layer_group':"Open Street Map"},
  'type': 'fill-extrusion',
  'source': 'park',
  'layout': {
      // Make the layer visible by default.
      'visibility': 'none'
  },
  'paint': {
  // Get the `fill-extrusion-color` from the source `color` property.
  'fill-extrusion-color': 'green',

  // Get `fill-extrusion-height` from the source `height` property.
  'fill-extrusion-height': 0.7,
  'fill-extrusion-base':0,
  // Make extrusions slightly opaque to see through indoor walls.
  'fill-extrusion-opacity': 1
  },
  'minzoom': 10 // Minimum zoom level for visibility
}},
"parking_c":{
  "source":"./parking_c.geojson",
  "config":{
  'id': 'parking_c',
  'metadata':{'desc':'parking','layer_group':"Open Street Map"},
  'type': 'fill-extrusion',
  'source': 'parking_c',
  'layout': {
      // Make the layer visible by default.
      'visibility': 'none'
  },
  'paint': {
  // Get the `fill-extrusion-color` from the source `color` property.
  'fill-extrusion-color': 'black',

  // Get `fill-extrusion-height` from the source `height` property.
  'fill-extrusion-height': 0.5,
  'fill-extrusion-base':0,
  // Make extrusions slightly opaque to see through indoor walls.
  'fill-extrusion-opacity': 1
  },
  'minzoom': 10 // Minimum zoom level for visibility
}},

"pitch":{
  "source":"./pitch.geojson",
  "config":{
  'id': 'pitch',
  'metadata':{'desc':'pitch','layer_group':"Open Street Map"},
  'type': 'fill-extrusion',
  'source': 'pitch',
  'layout': {
      // Make the layer visible by default.
      'visibility': 'none'
  },
  'paint': {
  // Get the `fill-extrusion-color` from the source `color` property.
  'fill-extrusion-color': 'darkgreen',

  // Get `fill-extrusion-height` from the source `height` property.
  'fill-extrusion-height': 1,
  'fill-extrusion-base':0,
  // Make extrusions slightly opaque to see through indoor walls.
  'fill-extrusion-opacity': 1
  },
  'minzoom': 10 // Minimum zoom level for visibility
}},
"playground_c":{
  "source":"./playground_c.geojson",
  "config":{
  'id': 'playground_c',
  'metadata':{'desc':'playground','layer_group':"Open Street Map"},
  'type': 'fill-extrusion',
  'source': 'playground_c',
  'layout': {
      // Make the layer visible by default.
      'visibility': 'none'
  },
  'paint': {
  // Get the `fill-extrusion-color` from the source `color` property.
  'fill-extrusion-color': 'green',

  // Get `fill-extrusion-height` from the source `height` property.
  'fill-extrusion-height': 1,
  'fill-extrusion-base':0,
  // Make extrusions slightly opaque to see through indoor walls.
  'fill-extrusion-opacity': 1
  },
  'minzoom': 10 // Minimum zoom level for visibility
}},
"playground":{
  "source":"./playground.geojson",
  "config":{
  'id': 'playground',
  'metadata':{'desc':'playground','layer_group':"Open Street Map"},
  'type': 'fill-extrusion',
  'source': 'playground',
  'layout': {
      // Make the layer visible by default.
      'visibility': 'none'
  },
  'paint': {
  // Get the `fill-extrusion-color` from the source `color` property.
  'fill-extrusion-color': 'green',

  // Get `fill-extrusion-height` from the source `height` property.
  'fill-extrusion-height': 1,
  'fill-extrusion-base':0,
  // Make extrusions slightly opaque to see through indoor walls.
  'fill-extrusion-opacity': 1
  },
  'minzoom': 10 // Minimum zoom level for visibility
}},
"police_c":{
  "source":"./police_c.geojson",
  "config":{
  'id': 'police_c',
  'metadata':{'desc':'police','layer_group':"Open Street Map"},
  'type': 'fill-extrusion',
  'source': 'police_c',
  'layout': {
      // Make the layer visible by default.
      'visibility': 'none'
  },
  'paint': {
  // Get the `fill-extrusion-color` from the source `color` property.
  'fill-extrusion-color': 'blue',

  // Get `fill-extrusion-height` from the source `height` property.
  'fill-extrusion-height': 0.5,

  // Make extrusions slightly opaque to see through indoor walls.
  'fill-extrusion-opacity': 1
  },
  'minzoom': 10 // Minimum zoom level for visibility
}},
"police":{
  "source":"./police.geojson",
  "config":{
  'id': 'police',
  'metadata':{'desc':'police','layer_group':"Open Street Map"},
  'type': 'fill-extrusion',
  'source': 'police',
  'layout': {
      // Make the layer visible by default.
      'visibility': 'none'
  },
  'paint': {
  // Get the `fill-extrusion-color` from the source `color` property.
  'fill-extrusion-color': 'blue',

  // Get `fill-extrusion-height` from the source `height` property.
  'fill-extrusion-height': 0.5,
  'fill-extrusion-base':0,
  // Make extrusions slightly opaque to see through indoor walls.
  'fill-extrusion-opacity': 1
  },
  'minzoom': 10 // Minimum zoom level for visibility
}},

"quarry":{
  "source":"./quarry.geojson",
  "config":{
  'id': 'quarry',
  'metadata':{'desc':'quarry','layer_group':"Open Street Map"},
  'type': 'fill-extrusion',
  'source': 'quarry',
  'layout': {
      // Make the layer visible by default.
      'visibility': 'none'
  },
  'paint': {
  // Get the `fill-extrusion-color` from the source `color` property.
  'fill-extrusion-color': 'tan',

  // Get `fill-extrusion-height` from the source `height` property.
  'fill-extrusion-height': 0.5,
  'fill-extrusion-base':0,
  // Make extrusions slightly opaque to see through indoor walls.
  'fill-extrusion-opacity': 1
  },
  'minzoom': 10 // Minimum zoom level for visibility
}},

"recreation_c":{
  "source":"./recreation_c.geojson",
  "config":{
  'id': 'recreation_c',
  'metadata':{'desc':'recreation','layer_group':"Open Street Map"},
  'type': 'fill-extrusion',
  'source': 'recreation_c',
  'layout': {
      // Make the layer visible by default.
      'visibility': 'none'
  },
  'paint': {
  // Get the `fill-extrusion-color` from the source `color` property.
  'fill-extrusion-color': 'green',

  // Get `fill-extrusion-height` from the source `height` property.
  'fill-extrusion-height': 0.7,
  'fill-extrusion-base':0,
  // Make extrusions slightly opaque to see through indoor walls.
  'fill-extrusion-opacity': 1
  },
  'minzoom': 10 // Minimum zoom level for visibility
}},

"recreation":{
  "source":"./recreation.geojson",
  "config":{
  'id': 'recreation',
  'metadata':{'desc':'recreation','layer_group':"Open Street Map"},
  'type': 'fill-extrusion',
  'source': 'recreation',
  'layout': {
      // Make the layer visible by default.
      'visibility': 'none'
  },
  'paint': {
  // Get the `fill-extrusion-color` from the source `color` property.
  'fill-extrusion-color': 'green',

  // Get `fill-extrusion-height` from the source `height` property.
  'fill-extrusion-height': 0.7,
  'fill-extrusion-base':0,
  // Make extrusions slightly opaque to see through indoor walls.
  'fill-extrusion-opacity': 1
  },
  'minzoom': 10 // Minimum zoom level for visibility
}},

"religious_c":{
  "source":"./religious_c.geojson",
  "config":{
  'id': 'religious_c',
  'metadata':{'desc':'religious','layer_group':"Open Street Map"},
  'type': 'fill-extrusion',
  'source': 'religious_c',
  'layout': {
      // Make the layer visible by default.
      'visibility': 'none'
  },
  'paint': {
  // Get the `fill-extrusion-color` from the source `color` property.
  'fill-extrusion-color': 'purple',

  // Get `fill-extrusion-height` from the source `height` property.
  'fill-extrusion-height': 0.5,
  'fill-extrusion-base':0,
  // Make extrusions slightly opaque to see through indoor walls.
  'fill-extrusion-opacity': 1
  },
  'minzoom': 10 // Minimum zoom level for visibility
}},

"religious":{
  "source":"./religious.geojson",
  "config":{
  'id': 'religious',
  'metadata':{'desc':'religious','layer_group':"Open Street Map"},
  'type': 'fill-extrusion',
  'source': 'religious',
  'layout': {
      // Make the layer visible by default.
      'visibility': 'none'
  },
  'paint': {
  // Get the `fill-extrusion-color` from the source `color` property.
  'fill-extrusion-color': 'purple',

  // Get `fill-extrusion-height` from the source `height` property.
  'fill-extrusion-height': 0.5,
  'fill-extrusion-base':0,
  // Make extrusions slightly opaque to see through indoor walls.
  'fill-extrusion-opacity': 1
  },
  'minzoom': 10 // Minimum zoom level for visibility
}},

"retail_c":{
  "source":"./retail_c.geojson",
  "config":{
  'id': 'retail_c',
  'metadata':{'desc':'retail','layer_group':"Open Street Map"},
  'type': 'fill-extrusion',
  'source': 'retail_c',
  'layout': {
      // Make the layer visible by default.
      'visibility': 'none'
  },
  'paint': {
  // Get the `fill-extrusion-color` from the source `color` property.
  'fill-extrusion-color': 'pink',

  // Get `fill-extrusion-height` from the source `height` property.
  'fill-extrusion-height': 0.5,
  'fill-extrusion-base':0,
  // Make extrusions slightly opaque to see through indoor walls.
  'fill-extrusion-opacity': 1
  },
  'minzoom': 10 // Minimum zoom level for visibility
}},

"retail":{
  "source":"./retail.geojson",
  "config":{
  'id': 'retail',
  'metadata':{'desc':'retail','layer_group':"Open Street Map"},
  'type': 'fill-extrusion',
  'source': 'retail',
  'layout': {
      // Make the layer visible by default.
      'visibility': 'none'
  },
  'paint': {
  // Get the `fill-extrusion-color` from the source `color` property.
  'fill-extrusion-color': 'pink',

  // Get `fill-extrusion-height` from the source `height` property.
  'fill-extrusion-height': 0.5,
  'fill-extrusion-base':0,
  // Make extrusions slightly opaque to see through indoor walls.
  'fill-extrusion-opacity': 1
  },
  'minzoom': 10 // Minimum zoom level for visibility
}},
"runway":{
  "source":"./runway.geojson",
  "config":{
  'id': 'runway',
  'metadata':{'desc':'runway','layer_group':"Open Street Map"},
  'type': 'fill-extrusion',
  'source': 'runway',
  'layout': {
      // Make the layer visible by default.
      'visibility': 'none'
  },
  'paint': {
  // Get the `fill-extrusion-color` from the source `color` property.
  'fill-extrusion-color': 'black',

  // Get `fill-extrusion-height` from the source `height` property.
  'fill-extrusion-height': 1,
  'fill-extrusion-base':0,
  // Make extrusions slightly opaque to see through indoor walls.
  'fill-extrusion-opacity': 1
  },
  'minzoom': 10 // Minimum zoom level for visibility
}},
"school_c":{
  "source":"./school_c.geojson",
  "config":{
  'id': 'school_c',
  'metadata':{'desc':'school','layer_group':"Open Street Map"},
  'type': 'fill-extrusion',
  'source': 'school_c',
  'layout': {
      // Make the layer visible by default.
      'visibility': 'none'
  },
  'paint': {
  // Get the `fill-extrusion-color` from the source `color` property.
  'fill-extrusion-color': 'yellow',

  // Get `fill-extrusion-height` from the source `height` property.
  'fill-extrusion-height': 0.5,
  'fill-extrusion-base':0,
  // Make extrusions slightly opaque to see through indoor walls.
  'fill-extrusion-opacity': 1
  },
  'minzoom': 10 // Minimum zoom level for visibility
}},
"school":{
  "source":"./school.geojson",
  "config":{
  'id': 'school',
  'metadata':{'desc':'school','layer_group':"Open Street Map"},
  'type': 'fill-extrusion',
  'source': 'school',
  'layout': {
      // Make the layer visible by default.
      'visibility': 'none'
  },
  'paint': {
  // Get the `fill-extrusion-color` from the source `color` property.
  'fill-extrusion-color': 'yellow',

  // Get `fill-extrusion-height` from the source `height` property.
  'fill-extrusion-height': 0.5,
  'fill-extrusion-base':0,
  // Make extrusions slightly opaque to see through indoor walls.
  'fill-extrusion-opacity': 1
  },
  'minzoom': 10 // Minimum zoom level for visibility
}},
"scrub":{
  "source":"./scrub.geojson",
  "config":{
  'id': 'scrub',
  'metadata':{'desc':'scrub','layer_group':"Open Street Map"},
  'type': 'fill-extrusion',
  'source': 'scrub',
  'layout': {
      // Make the layer visible by default.
      'visibility': 'none'
  },
  'paint': {
  // Get the `fill-extrusion-color` from the source `color` property.
  'fill-extrusion-color': 'darkgreen',

  // Get `fill-extrusion-height` from the source `height` property.
  'fill-extrusion-height': 5,
  'fill-extrusion-base':0,
  // Make extrusions slightly opaque to see through indoor walls.
  'fill-extrusion-opacity': 1
  },
  'minzoom': 10 // Minimum zoom level for visibility
}},

"scrubc":{
  "source":"./scrubc.geojson",
  "config":{
  'id': 'scrubc',
  'metadata':{'desc':'scrub','layer_group':"Open Street Map"},
  'type': 'fill-extrusion',
  'source': 'scrubc',
  'layout': {
      // Make the layer visible by default.
      'visibility': 'none'
  },
  'paint': {
  // Get the `fill-extrusion-color` from the source `color` property.
  'fill-extrusion-color': 'darkgreen',

  // Get `fill-extrusion-height` from the source `height` property.
  'fill-extrusion-height': 5,
  'fill-extrusion-base':0,
  // Make extrusions slightly opaque to see through indoor walls.
  'fill-extrusion-opacity': 1
  },
  'minzoom': 10 // Minimum zoom level for visibility
}},

"sports_centre_c":{
  "source":"./sports_centre_c.geojson",
  "config":{
  'id': 'sports_centre_c',
  'metadata':{'desc':'sports_centre','layer_group':"Open Street Map"},
  'type': 'fill-extrusion',
  'source': 'sports_centre_c',
  'layout': {
      // Make the layer visible by default.
      'visibility': 'none'
  },
  'paint': {
  // Get the `fill-extrusion-color` from the source `color` property.
  'fill-extrusion-color': 'green',

  // Get `fill-extrusion-height` from the source `height` property.
  'fill-extrusion-height': 0.5,
  'fill-extrusion-base':0,
  // Make extrusions slightly opaque to see through indoor walls.
  'fill-extrusion-opacity': 1
  },
  'minzoom': 10 // Minimum zoom level for visibility
}},
"sports_centre":{
  "source":"./sports_centre.geojson",
  "config":{
  'id': 'sports_centre',
  'metadata':{'desc':'sports_centre','layer_group':"Open Street Map"},
  'type': 'fill-extrusion',
  'source': 'sports_centre',
  'layout': {
      // Make the layer visible by default.
      'visibility': 'none'
  },
  'paint': {
  // Get the `fill-extrusion-color` from the source `color` property.
  'fill-extrusion-color': 'green',

  // Get `fill-extrusion-height` from the source `height` property.
  'fill-extrusion-height': 0.5,
  'fill-extrusion-base':0,
  // Make extrusions slightly opaque to see through indoor walls.
  'fill-extrusion-opacity': 1
  },
  'minzoom': 10 // Minimum zoom level for visibility
}},

"track_c":{
  "source":"./track_c.geojson",
  "config":{
  'id': 'track_c',
  'metadata':{'desc':'track_c','layer_group':"Open Street Map"},
  'type': 'fill-extrusion',
  'source': 'track_c',
  'layout': {
      // Make the layer visible by default.
      'visibility': 'none'
  },
  'paint': {
  // Get the `fill-extrusion-color` from the source `color` property.
  'fill-extrusion-color': 'tan',

  // Get `fill-extrusion-height` from the source `height` property.
  'fill-extrusion-height': 1,
  'fill-extrusion-base':0,
  // Make extrusions slightly opaque to see through indoor walls.
  'fill-extrusion-opacity': 1
  },
  'minzoom': 10 // Minimum zoom level for visibility
}},
"track":{
  "source":"./track.geojson",
  "config":{
  'id': 'track',
  'metadata':{'desc':'track','layer_group':"Open Street Map"},
  'type': 'fill-extrusion',
  'source': 'track',
  'layout': {
      // Make the layer visible by default.
      'visibility': 'none'
  },
  'paint': {
  // Get the `fill-extrusion-color` from the source `color` property.
  'fill-extrusion-color': 'tan',
  'fill-extrusion-base':0,
  // Get `fill-extrusion-height` from the source `height` property.
  'fill-extrusion-height': 1,

  // Make extrusions slightly opaque to see through indoor walls.
  'fill-extrusion-opacity': 1
  },
  'minzoom': 10 // Minimum zoom level for visibility
}},
"Tree Cover SA":{
  "source":"./Tree_Coverage_per_SA.geojson",
  "config":{
  'id': 'Tree Cover SA',
  'metadata':{'desc':'Tree Cover SA','layer_group':'Tree Cover SA'},
  'type': 'fill',
  'source': 'Tree Cover SA',
  'layout': {
      // Make the layer visible by default.
      'visibility': 'none'
  },
  'paint': {
  // Get the `fill-extrusion-color` from the source `color` property.
  'fill-color':[
                        'interpolate',
                            ['linear'],
                             ['to-number',['get','Tree_Canopy_Percent']],
                            0,
                            '#FFFFFF', // White
                            10,
                            '#F0FFF0', // Honeydew
                            20,
                            '#D0FFD0', // Mint Green
                            30,
                            '#B0FFB0', // Pale Green
                            40,
                            '#90FF90', // Light Green
                            50,
                            '#70FF70', // Green
                            60,
                            '#50FF50', // Medium Green
                            70,
                            '#30FF30', // Lime Green
                            80,
                            '#10FF10', // Neon Green
                            90,
                            '#00FF00', // Pure Green
                            100,
                            '#00FF00' // Pure Green
                          ],

  'fill-outline-color':'black',
  
                
  // Make extrusions slightly opaque to see through indoor walls.
  'fill-opacity': 0.5
  },
  'minzoom': 10 // Minimum zoom level for visibility
}},

'Tree Cover SA outline':{
  "source":"./Tree_Coverage_per_SA.geojson",
  "config":{
  'id': 'Tree Cover SA outline',
  'metadata':{'desc':'Tree Cover SA outline','layer_group':'Tree Cover SA'},
  'type': 'line',
  'source': 'Tree Cover SA outline',
  'layout': {
      // Make the layer visible by default.
      'visibility': 'none'
  },
  'paint': {
    'line-color': '#000',
    'line-width': 2
    },    
  'minzoom': 10 // Minimum zoom level for visibility
}},

"Tree Cover ED":{
  "source":"./Tree_Coverage_per_Ed.geojson",
  "config":{
  'id': 'Tree Cover ED',
  'metadata':{'desc':'Tree Cover ED','layer_group':'Tree Cover ED'},
  'type': 'fill',
  'source': 'Tree Cover ED',
  'layout': {
      // Make the layer visible by default.
      'visibility': 'none'
  },
  'paint': {
  // Get the `fill-extrusion-color` from the source `color` property.
  'fill-color':[
    'interpolate',
                  ['linear'],
                    ['to-number',['get','Tree_Canopy_Percent']],
                  0,
                  '#FFFFFF', // White
                  10,
                  '#F0FFF0', // Honeydew
                  20,
                  '#D0FFD0', // Mint Green
                  30,
                  '#B0FFB0', // Pale Green
                  40,
                  '#90FF90', // Light Green
                  50,
                  '#70FF70', // Green
                  60,
                  '#50FF50', // Medium Green
                  70,
                  '#30FF30', // Lime Green
                  80,
                  '#10FF10', // Neon Green
                  90,
                  '#00FF00', // Pure Green
                  100,
                  '#00FF00' // Pure Green
                ],

  // Get `fill-extrusion-height` from the source `height` property.
  'fill-outline-color':'black',
  // Make extrusions slightly opaque to see through indoor walls.
  'fill-opacity': 0.5,
  },
  'minzoom': 10 // Minimum zoom level for visibility
}},


'Tree Cover ED outline':{
  "source":"./Tree_Coverage_per_Ed.geojson",
  "config":{
  'id': 'Tree Cover ED outline',
  'metadata':{'desc':'Tree Cover ED outline','layer_group':'Tree Cover ED'},
  'type': 'line',
  'source': 'Tree Cover ED outline',
  'layout': {
      // Make the layer visible by default.
      'visibility': 'none'
  },
  'paint': {
    'line-color': '#000',
    'line-width': 2
    },    
  'minzoom': 10 // Minimum zoom level for visibility
}},


"university_c":{
  "source":"./university_c.geojson",
  "config":{
  'id': 'university_c',
  'metadata':{'desc':'university_c','layer_group':"Open Street Map"},
  'type': 'fill-extrusion',
  'source': 'university_c',
  'layout': {
      // Make the layer visible by default.
      'visibility': 'none'
  },
  'paint': {
  // Get the `fill-extrusion-color` from the source `color` property.
  'fill-extrusion-color': 'yellow',

  // Get `fill-extrusion-height` from the source `height` property.
  'fill-extrusion-height': 0.5,
  'fill-extrusion-base':0,
  // Make extrusions slightly opaque to see through indoor walls.
  'fill-extrusion-opacity': 1
  },
  'minzoom': 10 // Minimum zoom level for visibility
}},

"university":{
  "source":"./university.geojson",
  "config":{
  'id': 'university',
  'metadata':{'desc':'university','layer_group':"Open Street Map"},
  'type': 'fill-extrusion',
  'source': 'university',
  'layout': {
      // Make the layer visible by default.
      'visibility': 'none'
  },
  'paint': {
  // Get the `fill-extrusion-color` from the source `color` property.
  'fill-extrusion-color': 'yellow',

  // Get `fill-extrusion-height` from the source `height` property.
  'fill-extrusion-height': 0.5,
  'fill-extrusion-base':0,
  // Make extrusions slightly opaque to see through indoor walls.
  'fill-extrusion-opacity': 1
  },
  'minzoom': 10 // Minimum zoom level for visibility
}},

"wetland_c":{
  "source":"./wetland_c.geojson",
  "config":{
  'id': 'wetland_c',
  'metadata':{'desc':'wetland_c','layer_group':"Open Street Map"},
  'type': 'fill-extrusion',
  'source': 'wetland_c',
  'layout': {
      // Make the layer visible by default.
      'visibility': 'none'
  },
  'paint': {
  // Get the `fill-extrusion-color` from the source `color` property.
  'fill-extrusion-color': 'green',

  // Get `fill-extrusion-height` from the source `height` property.
  'fill-extrusion-height': 0.5,
  'fill-extrusion-base':0,
  // Make extrusions slightly opaque to see through indoor walls.
  'fill-extrusion-opacity': 1
  },
  'minzoom': 10 // Minimum zoom level for visibility
}},

"wetland":{
  "source":"./wetland.geojson",
  "config":{
  'id': 'wetland',
  'metadata':{'desc':'wetland','layer_group':"Open Street Map"},
  'type': 'fill-extrusion',
  'source': 'wetland',
  'layout': {
      // Make the layer visible by default.
      'visibility': 'none'
  },
  'paint': {
  // Get the `fill-extrusion-color` from the source `color` property.
  'fill-extrusion-color': 'green',

  // Get `fill-extrusion-height` from the source `height` property.
  'fill-extrusion-height': 0.5,
  'fill-extrusion-base':0,
  // Make extrusions slightly opaque to see through indoor walls.
  'fill-extrusion-opacity': 1
  },
  'minzoom': 10 // Minimum zoom level for visibility
}},

"wood_c":{
  "source":"./wood_c.geojson",
  "config":{
  'id': 'wood_c',
  'metadata':{'desc':'wood','layer_group':"Open Street Map"},
  'type': 'fill-extrusion',
  'source': 'wood_c',
  'layout': {
      // Make the layer visible by default.
      'visibility': 'none'
  },
  'paint': {
  // Get the `fill-extrusion-color` from the source `color` property.
  'fill-extrusion-color': 'darkgreen',

  // Get `fill-extrusion-height` from the source `height` property.
  'fill-extrusion-height': 10,
  'fill-extrusion-base':0,
  // Make extrusions slightly opaque to see through indoor walls.
  'fill-extrusion-opacity': 1
  },
  'minzoom': 10 // Minimum zoom level for visibility
}},
}
}