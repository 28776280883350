const BUS_API_URL = 'https://galway-3d-dev.vercel.app/api/server/';

const routes={
  "2975_46238":"164 - Sydney Parade DART Station - UCD",
"2987_37718":"525 - Queen Street Galway - Rossaveel Pier",
"2990_40267":"DK07 - Monaghan (Opp St Louis Convent School) - Hoey's Lane (DKIT)",
"2991_37732":"930 - Swanlinbar, Swanlinbar - Cavan General Hospital",
"3001_36302":"290A - Tralee, Tesco, The Square - Tralee, Tesco, The Square",
"3001_36303":"290B - Tralee, Tesco, The Square - Tralee, Tesco, The Square",
"3006_37726":"863 - Blessington, Tourist Office - Pearse Station, Westland Row Church",
"3012_40278":"GM02 - Castlebar Courthouse - Galway, Woodquay",
"3015_37967":"191 - Stadalt Cross, Mountain View - Dublin, Marlborough Street",
"3017_40327":"VI02 - Granard, Mackens Service Station - Cavan Institute",
"3023_40285":"MB01 - Ballymore, Ballymore Westmeath - Moate, Moate Business School",
"3024_40325":"UM07 - Scout's Den - College",
"3025_36297":"325 - County Limerick, Ballyshonikin - Charleville, Charleville",
"3025_36298":"327 - Tankardstown Cemetery - Supervalu, Sarsfield Street",
"3026_40268":"DK01 - Dunshaughlin (Euromart Shop) - Dundalk, DkIT Dublin Road",
"3026_40269":"DK02 - Moynalty, Moynalty - Dundalk, DkIT Dublin Road",
"3026_40270":"DK03 - Market Square Navan - Dundalk, DkIT Dublin Road",
"3026_40271":"DK04 - Bailieborough, Tesco - Dundalk, DkIT Dublin Road",
"3029_35241":"741 - Dublin Airport - Swords, Premier Inn",
"3031_37707":"231 - Ardgroom - Parnell Place (Opp Car Park)",
"3035_37724":"842 - Ballymahon, Ballymahon - Merrion Square, Holles Street",
"3038_40309":"UC05 - The Mall (Waterford House of Crystal) - Bishopstown, CIT Campus",
"3038_40323":"UM15 - Carrick-on-Suir, Carrick On Suir Greenside - University Campus",
"3038_40330":"WI05 - Carrigeen Business Park, Cuirt An Rí - Parnell Street (WCFE)",
"3045_37723":"835 - Tullamore, Tullamore Retail Park - Tullamore, Tullamore Retail Park",
"3048_36873":"829 - Laois Shopping Centre - Tullamore Hospital",
"3048_36874":"830 - Portlaoise, Kilminchy (Laurel Court) - Tullamore Hospital",
"3048_36875":"831 - Borris in Ossory - Laois Shopping Centre",
"3048_36876":"832 - Errill, Errill Square - Kilminchy Avenue",
"3048_36877":"837 - Tullamore (Offaly), Opposite Train Station - Lough Sheever Corporate Park, Longford Road",
"3048_36878":"838 - Kilkenny, Market Yard - Market Street Mountmellick",
"3052_40277":"GM13 - Monaghan, Dawson Street - Galway Cathedral",
"3052_40297":"UM03 - Hill Street Dundalk - University Campus",
"3052_40300":"UM09 - Athboy (Opp Darnley Lodge) - University Campus",
"3052_40321":"UM10 - Carrickmacross (Paddy Power) - College",
"3060_40669":"700 - Leopardstown Clayton Hotel - Dublin Airport",
"3060_40670":"702 - Dublin Airport - Charlesland Road (Seaborne View Apts)",
"3060_40671":"703 - Killiney (Fitzpatrick Castle Hotel) - Dublin Airport",
"3060_40672":"704x - Cork, Lower Glanmire Road - Dublin Airport",
"3060_40674":"706x - Galway Coach Station - Dublin Airport",
"3060_40675":"706 - Galway Coach Station - Dublin Airport",
"3064_41295":"809 - Galway City  - Glásan Student Village",
"3070_40571":"393 - Liberty Square Thurles - Gladstone Street Clonmel",
"3070_40572":"394 - Gladstone Street Clonmel - Liberty Square Thurles",
"3070_40573":"395 - Templemore Town Hall - Nenagh, Nenagh",
"3070_40574":"396 - Liberty Square Thurles - Gladstone Street Clonmel",
"3070_40575":"397 - Nenagh, Nenagh - Liberty Square Thurles",
"3070_40576":"812 - Urlingford, Josephines Urlingford - Roscrea, Rosemary Square Roscrea",
"3082_36320":"824 - Market Street Mountmellick - Dublin (UCD Stillorgan Rd Flyover)",
"3083_40284":"IW08 - Market Street Mountmellick - Carlow, Carlow Institute of Further Education",
"3084_40293":"UM13 - Gorey (Main St - Whitmore Jewellers) - University Campus",
"3084_40315":"UL01 - Enniscorthy, Island Road Enniscorthy - Mary Immaculate College",
"3084_40324":"UM04 - Crescent Quay - College",
"3089_40266":"DF01 - Carlingford (Opp Tourist Office) - Drogheda (Institute of Further Education)",
"3089_40301":"OF01 - Chapel Hill (Louth), Clos na Manach Estate - Hoey's Lane (DKIT)",
"3092_37743":"AI02 - Casey's Filling Station - Fardrum, Athlone A I T",
"3093_36932":"961 - Paddy Og's Pub, Crolly - Letterkenny, Dunnes Stores Letterkenny",
"3093_36933":"963 - Gortlee, Tesco Shopping Centre - Gortlee, Tesco Shopping Centre",
"3094_40326":"UM08 - Dundrum, Upper Churchtown Road (Notre Dame School) - University Campus",
"3096_40298":"UM05 - Cathedral - University Campus",
"3099_36628":"436 - Circular Road Ballina - Main Street",
"3099_36629":"452 - Glenamoy - Glenamoy",
"3099_36630":"496 - Glenamoy - Circular Road Ballina",
"3099_36631":"497 - Glenamoy - Glenamoy",
"3099_36633":"499 - Glenamoy - Circular Road Ballina",
"3099_46236":"498 - Glenamoy - Stephen Garvey Way Castlebar",
"3102_36215":"881 - Graiguenamanagh, Graiguenamanagh - Kilkenny Castle",
"3102_36216":"882 - New Ross (The Quay) - Kilkenny, Ormond Road",
"3105_37710":"309 - Kilduff - Glentworth, William Street",
"3105_37712":"319 - Kilduff - Glentworth, William Street",
"3105_37713":"352 - Kilduff - Dundrum, Dundrum Town Centre",
"3108_46283":"991 - Killybegs - Letterkenny",
"3111_37719":"663 - Tallow - Abbeyside (Waterford), Minnies Lough Bar",
"3112_40362":"879 - Gorey, Main Street - Gorey Shopping Centre",
"3113_32198":"DK05 - Cabra (Cavan), Forest Park - Dundalk I.T (Main Gate)",
"3114_37728":"869 - Parkwest, Park West Station - Greenoge, Greenoge",
"3118_37716":"437 - Tourmakeady Post Office - Heatons Castlebar",
"3124_37715":"433 - The Square Roscommon - Galway Cathedral",
"3126_40314":"UL18 - Coolagh (Waterford), Maxol Service Station - Carrigeen Business Park, Cuirt An Rí",
"3134_40310":"UC04 - Mooncoin (Church) - Mardyke (Presentation College)",
"3135_46286":"VI04 - Cross Keys - Cavan, Breffni College",
"3140_40305":"TT01 - Merchants Quay (Riverside) - Dingle, Dingle",
"3140_40306":"TT02 - Killerisk, Manor West - Tralee, Tralee Institute Of Technology",
"3140_40308":"UC03 - Killarney, Killarney Outlet Centre - Tralee (Princes St Brandon Hotel)",
"3146_36257":"965 - Meenlaragh Teach Dixon - Letterkenny Library",
"3147_37730":"886 - Monamolin - Gorey, Rafter Street",
"3158_36316":"441 - Raftery's Pub, Corofin, Co. Galway - Tuam, Bishop Street Cathedral",
"3158_36318":"488 - Kilcurriff - Kilcurriff",
"3158_36319":"527 - Kilcurriff - Tourist Office, Seapoint Promenade",
"3158_46100":"484 - Kilcurriff - Kilcurriff",
"3159_40283":"IW01 - Loughboy - Carlow, Institute of Technology Carlow",
"3159_40329":"WI01 - Shandon Park - Institute of Technology Waterford",
"3160_36546":"428 - Tuam, Bishop Street Cathedral - Eyre Square Galway",
"3160_45984":"427 - Dunmore - Eyre Square Galway",
"3160_45985":"435 - Ballinrobe - Eyre Square Galway",
"3161_37744":"ATH1 - Athlone (Golden Island SC) - Athlone (Golden Island SC)",
"3162_40311":"UL04 - Clarecastle (Doab Butchers Main St) - Stables University of Limerick",
"3163_40123":"951 - Cockhill Road - Letterkenny, Mr. Chippy Chip Shop",
"3163_40131":"976 - Ballymagan, Saint Mary's Church - Bridgend",
"3164_37717":"449 - Keane's Tile Shop - Sligo, Ash Lane Sligo",
"3166_40273":"GM11 - High Street Drumshanbo - Monivee (Galway City), ATU Galway City",
"3166_40276":"GM10 - Keane's Tile Shop - Carrick-on-Shannon, Carrick On Shannon Coffee Shop",
"3166_40295":"NG05 - Keane's Tile Shop - Wellpark",
"3166_40318":"UL06 - Keane's Tile Shop - O Connell St (Clancy Electrical)",
"3167_40260":"511 - Rathgar, Dartry Road - Ardilea, Mount Anville School",
"3174_36931":"593 - Ballyneety - Newcastle West",
"3177_40317":"UL05 - University of Limerick, University Of Limerick - Westport",
"3179_37738":"968 - Knockcroghery (Mayo), Church of the Holy Rosary - Castlecourt Hotel Westport",
"3179_37739":"970 - Breaffy House Resort, Breaffy House Resort - Stephen Garvey Way Castlebar",
"3183_40446":"UM02 - Birr, Birr Square - Maynooth, Straffan Road",
"3186_37749":"SL11 - Ballina (Dunnes Stores) - Sligo, ATU Sligo",
"3187_40294":"NG01 - NUIG - Ballina, Ballina Bus Station",
"3188_36278":"169 - Main Street - St Patricks Cathedral Dundalk",
"3188_36279":"916 - St Patricks Cathedral Dundalk - St Patricks Cathedral Dundalk",
"3188_36280":"917 - St Patricks Cathedral Dundalk - St Patricks Cathedral Dundalk",
"3188_36281":"918 - St Patricks Cathedral Dundalk - Willow Grove",
"3202_36258":"945 - Corduff Bus Depot - Belmullet, Civic Offices",
"3202_36259":"946 - Corduff Bus Depot - Belmullet (Opp EuroSpar)",
"3202_36260":"947 - Corduff Bus Depot - Belmullet (Opp EuroSpar)",
"3204_40274":"GM05 - Ballybane Rd (St James Rd) - Corduff Bus Depot",
"3204_40275":"GM08 - Westport, Westport Mill Street - University Rd (Cathedral)",
"3204_40302":"SL08 - Sligo, ATU Sligo  (Campus Salmon Sculpture) - Westport (Mill St Westbound Stop)",
"3204_40312":"UL11 - Ballinacurra, Scott's Bar - Corduff Bus Depot",
"3204_40313":"UL13 - University of Limerick (East Gate) - Westport (Mill St Westbound Stop)",
"3226_40307":"UC01 - Flynn's Yard - Western Road (Glucksman))",
"3226_40316":"UL03 - Killorglin, Fairfield Car Park - Stables University of Limerick",
"3229_40280":"IW02 - Curragh (Opp Post Office) - Carlow IT (Opp Main Entrance)",
"3236_40265":"BR01 - Gorey, Main Street - Bray (Wicklow), Bray Institute",
"3241_40577":"871 - Main Street Urlingford (Post Office) - Kilkenny, Ormond Road",
"3274_23507":"860 - Temple Bar, Aston Quay - Parkwest, Park West Avenue",
"3274_37963":"836 - Dublin, Millennium Spire - Corduff (Fingal), Cruiserath Road",
"3274_46124":"787 - Ballymun, Travelodge Hotel - Dublin Airport",
"3274_47272":"870 - O'Connell Street Upper - Damastown IBM",
"3290_36295":"932 - Crolly - Tourist Information O'Connell Street",
"3290_36296":"933 - Main Street Moville - Tourist Information O'Connell Street",
"3302_40264":"BI01 - TU Dublin Shuttle Bus",
"3351_37975":"Eastpoint Shutt - Eastpoint - Clontarf, Station Grounds",
"3365_48661":"974 - Glen - Letterkenny, Pearse Road Letterkenny",
"3378_48416":"931 - Belfast City Centre - Annagry",
"3383_48886":"Green - Parnell - Brides Glen",
"3383_48887":"Red - The Point - Tallaght",
"3394_42609":"176 - Cavan General Hospital - Monaghan, Monaghan",
"3394_42693":"929 - Derryconnessy, Devine's Cross - Cavan Institute",
"3394_42717":"C1 - Ballyhaise, Ballyhaise - Poles, Kilmore Roundabout",
"3394_42718":"C2 - Carrickane, Cavan Hospital - Ballinagh, Ballinagh",
"3394_42719":"C3 - Redhills - Farnham, Farnham Estate",
"3394_42720":"M1 - Knockatallon, Knockatallon - Monaghan, Monaghan Bus Station",
"3394_42721":"M2 - Ballybay, O'Duffy Terrace - Monaghan, Monaghan Bus Station",
"3394_42722":"M3 - Mullan Village, Mullan Village - Dublin Road (Monaghan), Latlorcan",
"3401_42606":"115D - Longwood (Spar) - Trim, Trim",
"3401_42612":"192 - Stamullen, Gilligans Cross - Balbriggan, Bus Stop at Train Station",
"3401_42613":"195 - Balbriggan, Bus Stop at Train Station - Garda Station",
"3401_42614":"196 - Knocksedan, Knocksedan - Swords, Pavilions Shopping Centre",
"3401_42723":"MH105 - Kildalkey Road, Athboy Church Car Park - Navan (Shopping Centre)",
"3401_48578":"188 - Kildalkey Road, Athboy Church Car Park - Drogheda, Hospital",
"3402_42674":"791A - Knock (Main St Opp Colemans) - Claremorris, Claremorris Dalton Street",
"3402_42675":"794 - Mayo, Abbeytots Creche - Mayo, Mayo Abbey NS",
"3402_42704":"978 - Belmullet, Civic Offices - Hawthorn Village, Train Station",
"3404_42616":"245C - Mitchelstown - New Square - The Grand Hotel",
"3404_42620":"253 - Kinsale, Town Car Park - Clonakilty, Clonakilty",
"3404_42656":"522 - Charleville, Ken's Chinese - Mallow, Mallow Town Park",
"3404_42657":"523 - Mitchelstown, Dealz - Mallow, Mallow Train Station",
"3404_46277":"254 - Bandon, Bandon - Bandon (Glaslynn Rd Kellehers Eastbound)",
"3404_49393":"232 - Allihies - Bantry - Kilcrohane",
"3405_42650":"391 - Semple Stadium, Thurles Train Station - Limerick, Limerick Arthur's Quay",
"3405_42685":"855 - Limerick Junction, Bohercrowe - Limerick Junction, Bohercrowe",
"3405_42691":"896 - The Commons - Brittas Road, LIT Tipperary",
"3405_47271":"854 - Roscrea, Roscrea Rail Station - Nenagh, Springfield Retail Park",
"3415_37725":"853 - Tolka Quay, Irish Ferries Terminal - Dublin City South, Georges Quay",
"3417_37746":"KL01 - Smithfield - Keelings Retail UC",
"3417_37747":"KL02 - Briarleas, Mosney Village Car Park - Dunbro, Keelings Retail",
"3417_46239":"KL03 - Santry - Keelings Retail UC",
"3419_36139":"872 - Ballyvaldon - Kilmuckridge",
"3419_36140":"875 - Blackwater - Clonard Village Wexford",
"3420_36440":"980A - Carrickmacross (Paddy Power) - Trinity College, Northern Ireland Tourist Board",
"3420_36441":"980B - Carrickmacross (Paddy Power) - Dublin, Wynn's Hotel",
"3420_46237":"980 - Wynn's Hotel, Dublin - Main Street, Ballybay",
"3424_49586":"920 - NUIG - Loughrea, Dublin Road Loughrea",
"3436_49808":"197 - Airside Ind. Estate, stop 7210 - Ashbourne Retail Pk, stop 101001",
"3440_40282":"IW04 - Carlow, Institute of Technology Carlow - Market Street Mountmellick",
"3440_40286":"UM12 - Naas (Post Office) - University Campus",
"3440_40287":"UM14 - Laois Shopping Centre - University Campus",
"3440_40288":"NG03 - Galway Cathedral - Carlow, Carlow Coach Park",
"3440_40299":"UM06 - Athy (C Bar Leinster Street) - University Campus",
"3445_26881":"430 - Ballina (Dunnes Stores) - Galway Cathedral",
"3445_48663":"707 - Cork - Dublin Airport",
"3447_49809":"AI05 - Edenderry - Athlone",
"3450_40578":"767 - Maynooth, Straffan Road - Dublin Airport",
"3450_44131":"777 - The Square - Tallaght - Dublin Airport",
"3460_50124":"IW06 - Abbeyleix - Carlow",
"3496_51234":"120 - Dublin - Edenderry",
"3496_51235":"125 - UCD - Newbridge",
"3496_51236":"126 - Dublin - Rathangan",
"3496_51237":"130 - Dublin - Athy",
"3496_51238":"120A - Dublin - Edenderry",
"3496_51239":"120B - Dublin - Newbridge",
"3496_51240":"120C - Enfield - Tullamore",
"3496_51241":"120D - Enfield - Tullamore",
"3496_51242":"120E - Dublin - Edenderry",
"3496_51243":"120F - Connolly Station - Newbridge Centre",
"3496_51244":"120X - Dublin - Edenderry",
"3496_51245":"126A - Dublin - Kildare",
"3496_51246":"126B - Dublin - Rathangan",
"3496_51247":"126D - Dublin - Kildare",
"3496_51248":"126E - Dublin - Kildare",
"3496_51249":"126T - Dublin - Kildare",
"3496_51250":"126U - Dublin UCD - Kildare",
"3496_51251":"126N - Dublin - Newbridge",
"3496_51252":"126X - Dublin - Rathangan",
"3496_51253":"130A - Dublin - Athy",
"3502_36461":"310 - Sarsfield St (Debenhams) - National Technology Park, Plassey PK Rd (Vistakon Ireland)",
"3502_36463":"726 - Laois Shopping Centre - Dublin Airport",
"3502_36465":"816 - Market Square Portarlington - Kildare Village",
"3502_51295":"600 - Cork City - Dublin City",
"3507_36313":"143 - Bray, Southern Cross Road (Killarney Road) - Sandyford, Blackthorn Avenue at LUAS Station",
"3507_36314":"144 - Bray, Southern Cross Road (Killarney Road) - Outside Train Station",
"3507_51298":"984N - Clerys - Kilcoole, Kilcoole",
"3511_51301":"IW07 - Gorey, Main Street - Carlow, Carlow College",
"3516_40344":"315 - Limerick, Limerick Arthur's Quay - Objekt Design",
"3516_51255":"722 - Killarney - Dublin Airport",
"3518_46098":"784 - Dublin Airport - Kelly's Corner, Charlotte Way",
"3518_48619":"782 - Dublin Airport - Dublin (Custom House Quay)",
"3518_51302":"785 - Belfast - Dublin",
"3534_37969":"210 - Hollyhill (Apple) - Castleview, Little Island",
"3534_37974":"250 - Cobh, Post Office - Ballydulea, Bayview Estate",
"3534_51296":"211 - Cobh, O'Neill Place - Inchera, Hoffman Park",
"3534_51707":"200 - Cobh - Cork City",
"3541_40669":"700 - Dublin Airport - Leopardstown",
"3541_40670":"702 - Dublin Airport - Greystones",
"3541_40672":"704x - Cork - Dublin - Dublin Airport",
"3541_40674":"706x - Galway - Athlone - Dublin Airport",
"3541_40675":"706 - Galway - Athlone - Dublin Airport",
"3541_45125":"705x - Derry - Belfast - Dublin",
"3541_51944":"703 - Dublin Airport - Killiney",
"3546_35217":"900 - Marshes Shopping Centre - Dublin, Cathal Brugha Street",
"3546_35218":"901D - Marshes Shopping Centre - The Helix",
"3546_35219":"901M - Monasterboice (Monasterboice Inn) - Trinity College, South Frederick's Street",
"3546_35220":"901 - Marshes Shopping Centre - Trinity College, South Frederick's Street",
"3546_35221":"904 - Marshes Shopping Centre - Belfield, Campus",
"3546_50123":"910 - Grange Rath - Dublin, Cathal Brugha Street",
"3546_52181":"912 - Grange Rath - Dublin City",
"3551_53599":"964 - Galway - Donegal",
"3556_42608":"1426 - Stranorlar (Meeting House Street) - Brandon Park, Waterfront Filling Station",
"3556_42615":"244 - Derry, Water Street - Main Street Moville",
"3556_42618":"251 - The Squealin' Pig Muff - Main Street Moville",
"3556_42619":"2529 - Quigley's Point, Tasty's Takeaway - The Diamond Carndonagh",
"3556_42622":"264 - Ballyshannon (Bus Station) - Letterkenny General Hospital",
"3556_42623":"271 - Burtonport, Burtonport - Letterkenny, Ramelton Road",
"3556_42630":"288 - Ballybofey, Ballybofey - Derry (Magee Campus Strand Road)",
"3556_42631":"290 - Ballybofey (McElhineys) - Letterkenny General Hospital",
"3556_42632":"292 - Donegal, Donegal Abbey Hotel - Ballyshannon (Bus Station)",
"3556_42633":"293 - An Clachan Gailf, Malinmore - Donegal, Donegal Abbey Hotel",
"3556_42634":"300 - Ballylar, Ballylar Post Office - Letterkenny, Ramelton Road",
"3556_42662":"564 - Ballinamore (AIB Main St) - Carrick-on-Shannon, Carrick On Shannon Coffee Shop",
"3556_42664":"567A - Drumshanbo, Drumshanbo - Manorhamilton (New Line)",
"3556_42665":"567 - Outside Train Station - Drumshanbo, Drumshanbo",
"3556_42666":"568 - Derreenavoggy (Roscommon), Miners Bar - Outside Train Station",
"3556_42670":"572 - Ballinamore - Sligo",
"3556_42671":"573 - Ballinamore (AIB Main St) - Carrigallen, Carrigallen",
"3556_42697":"953 - Greencastle, Post Office - Letterkenny, Ramelton Road",
"3556_42698":"954 - The Diamond Carndonagh - Ballyhillion, Malin Head Tower",
"3556_42699":"955 - Buncrana, Buncrana - The Diamond Carndonagh",
"3556_42701":"966 - Paddy Og's Pub, Crolly - Dungloe (Sweeney's Hotel)",
"3556_42702":"967 - Fintown - Main Street",
"3556_42707":"983A - Manorhamilton (New Line) - Kinlough (McGowans Spar)",
"3556_42708":"983 - Kinlough (McGowans Spar) - Ballyshannon (Bus Station)",
"3556_42709":"987 - Lifford, Lifford - Letterkenny, ATU Donegal Letterkenny",
"3556_42710":"988 - Cloghan (Donegal), Post Office - Letterkenny, Ramelton Road",
"3556_42711":"989 - Carrigans (Post Office) - Letterkenny, Pearse Road Letterkenny",
"3556_42712":"992A - Crolly, Crolly - Ballymanus (Donegal), Donegal Airport",
"3556_42713":"992 - Crolly, Crolly - Dungloe (Opp Sweeneys Hotel)",
"3556_42714":"993 - Carrickart, Carrigart Hotel - Carrickart, Carrigart Hotel",
"3556_42715":"994 - Portnoo - Donegal, Donegal Abbey Hotel",
"3556_42716":"995 - Muff, Main Street - Buncrana, Buncrana",
"3556_46550":"957 - Shrove - Moville - Derry",
"3556_49588":"952 - The Diamond Carndonagh - Derry, Patrick Street",
"3556_51543":"566 - Sligo - Dowra",
"3556_51581":"977 - Castlerea - Sligo",
"3556_51582":"981 - Coolaney - Sligo",
"3556_51583":"982 - Sligo - Ballyshannon",
"3570_37706":"230 - Cork, St. Patricks Quay - Skibbereen (Bridge St Cahalanes)",
"3570_51299":"229 - Bantry - Cork City",
"3570_53678":"228 - Kinsale - Cork City",
"3571_53680":"17 - Rialto - Blackrock",
"3571_53681":"18 - Palmerstown - Sandymount",
"3571_53682":"59 - Dun Laoghaire - Killiney Hill Park",
"3571_53683":"63 - Kilternan - Dún Laoghaire",
"3571_53684":"75 - Tallaght - Dun Laoghaire",
"3571_53685":"76 - Tallaght - Chapelizod",
"3571_53686":"102 - Dublin Airport - Sutton DART",
"3571_53687":"104 - Clontarf Station - DCU (The Helix)",
"3571_53688":"111 - Brides Glen - Kilbogget Pk",
"3571_53689":"114 - Ticknock - Blackrock",
"3571_53690":"161 - Rockbrook - Dundrum",
"3571_53691":"17D - Rialto - Dundrum Luas",
"3571_53692":"175 - UCD - Citywest",
"3571_53693":"184 - Newtownmountkennedy - Greystones - Bray",
"3571_53694":"185 - Powerscourt - Bray",
"3571_53695":"220 - DCU (The Helix) - Lady's Well Road",
"3571_53696":"236 - Blanchardstown - IBM Campus",
"3571_53697":"238 - Tyrrelstown - Mulhuddart",
"3571_53698":"270 - Dunboyle - Blanchardstown",
"3571_53699":"33A - Dublin Airport - Balbriggan",
"3571_53700":"33B - Swords - Portrane",
"3571_53701":"33T - Skerries - Donabate",
"3571_53702":"45A - Kilmacanogue - Dun Laoghaire",
"3571_53703":"45B - Kilmacanogue - Dun Laoghaire",
"3571_53704":"N6 - Finglas Village - Naomh Barróg GAA",
"3571_53705":"W4 - The Square - Blanchardstown SC",
"3571_53706":"63A - Kilternan - Dún Laoghaire",
"3571_53707":"75A - Tallaght - Dun Laoghaire",
"3571_53708":"76A - Tallaght - Blanchardstown",
"3571_53709":"L51 - Liffey Valley SC - Adamstown Station",
"3571_53710":"L52 - Blanchardstown SC - Adamstown Station",
"3571_53711":"102A - Swords - Sutton",
"3571_53712":"102C - Balgriffin - Sutton",
"3571_53713":"102P - Swords - Portmarnock",
"3571_53714":"102T - Swords - Sutton",
"3571_53715":"185T - Enniskerry - Bray",
"3571_53716":"220A - DCU (The Helix) - Lady's Well Road",
"3571_53717":"220T - Collins Avenue West - Finglas Garda Stn",
"3571_53718":"236A - Blanchardstown - IBM Campus",
"3571_53719":"236T - Scoil Oilibheir - Tyrrelstown",
"3571_53720":"270T - Castaheany Estate - Castleknock Community College",
"3571_53721":"W61 - Hazelhatch Station - Maynooth",
"3571_53722":"W62 - The Square - Newcastle",
"3574_40488":"820 - Edenderry (Ulster Bank) - Enfield, Main Street, SuperValu",
"3574_40490":"844 - Birr (Post Office Emmett Square) - Galway, Merchants Road Galway",
"3574_40491":"845 - Kinnegad, Kinnegad Harry's - Dublin City South, Saint Stephen's Green House",
"3574_40492":"847 - Portumna, Portumna - Dublin, Cathal Brugha Street",
"3574_53725":"843 - Birr, Birr Square - Tullamore Hospital",
"3578_40615":"500N - Dublin, Eden Quay - Swords Manor Brackenstown Road Swords",
"3578_40616":"500X - Abbeyvale Brackenstown Road - Dublin, Marlborough Street",
"3578_40617":"500 - Abbeyvale Brackenstown Road - Dublin, Marlborough Street",
"3578_40618":"501X - Swords, Pavilions Shopping Centre - Dublin, Marlborough Street",
"3578_40619":"501 - Swords, Pavilions Shopping Centre - Dublin, Marlborough Street",
"3578_40620":"502 - Swords, Forest Road - Dublin, Marlborough Street",
"3578_40621":"503 - Abbeyvale Brackenstown Road - Merrion Square South",
"3578_40622":"504 - Rathingle, Boroimhe Estate - Dublin, Marlborough Street",
"3578_40623":"505X - Dublin, Eden Quay - Malahide Road (Pavilions)",
"3578_40624":"505 - Rathingle, Rathingle Road - Dublin, Marlborough Street",
"3578_40625":"506X - Broadmeadow, Miller's Glen - Dublin, Marlborough Street",
"3578_40626":"506 - Dublin, Eden Quay - Swords Manor Brackenstown Road Swords",
"3578_50125":"507 - Swords - Dublin City",
"3579_53763":"SI03 - Belmullet - Sligo",
"3579_53764":"NG11 - Belmullet - Galway",
"3580_42651":"431 - Claremorris (Dalton St Hair Salon) - Carraroe, Carraroe",
"3580_42658":"546 - Castlerea, Castlerea Depot Railway Station - Ballinasloe, Portiuncula Hospital",
"3580_42659":"547 - Portumna, Portumna Castle and Abbey - Ballinasloe, Train Station",
"3580_42660":"548 - Loughrea - Ballinasloe, Train Station",
"3580_42694":"934 - Market Square Gort - Beatty's Jewellers",
"3580_48621":"438 - Galway City - Tuam",
"3580_53723":"432 - Carraroe, Carraroe - Clifden, Clifden (Market Street)",
"3581_42676":"800 - Arklow, Arklow Train Station - Carlow IT (Opp Main Entrance)",
"3581_42677":"817C - Carrick-on-Suir (Treacy Park Topaz) - Mullennaglogh, Grangemockler",
"3581_47818":"183 - Arklow - Sallins",
"3581_50579":"897 - Kilkenny, Ormond Road - Athy, Train Station",
"3581_53765":"887 - Carlow - New Ross",
"3584_40662":"627 - Ballygunner Waterford - Waterford, Clock Tower The Quay",
"3584_40664":"736 - Dublin Airport - WIT (Opp Main Entrance)",
"3584_40667":"874 - Hacketstown, Hacketstown - Carlow, Carlow Coach Park",
"3584_44093":"717 - Clonmel - Army Brks - Dublin Airport",
"3584_46122":"607 - Ardkeen Tesco - Abbey Park",
"3584_46123":"617 - Ballygunner Waterford - Slieverue Ferrybank",
"3584_47930":"735 - Limerick - Dublin Airport",
"3584_54113":"139 - Corduff (Fingal), TU Dublin Blanchardstown - Naas General Hospital",
"3585_48665":"720 - Galway - Dublin Airport",
"3586_36460":"300 - Dunnes, Friars Walk - Dublin, Arlington Hotel",
"3586_36464":"750 - Dundrum, Outside Luas Station - Dublin Airport",
"3591_54152":"DY01 - Kells - Dunboyne College of Further Education",
"3592_37748":"KY03 - Killarney, Gleneagle Hotel - Killarney, Gleneagle Hotel",
"3594_42617":"LR24 - Ballymahon, Ballymahon - Longford, St. Mel's Cathedral Car Park",
"3594_42667":"570A - Boyle - Boyle",
"3594_42668":"570 - Boyle - Roscommon, Abbeytown",
"3594_42669":"571 - Carrownanalt, Mining Experience Car Park - Boyle",
"3594_42687":"865 - Athlone - Mullingar",
"3594_51305":"819 - Athlone - Mullingar",
"3594_54155":"426 - Longford - Roscommon",
"3596_54157":"454 - Ballina - Castlebar",
"3602_51300":"UM11 - Gorey - Wicklow - Maynooth",
"3604_54283":"W1 - Clock Tower - Ballybeg",
"3604_54284":"W3 - Clock Tower - Meagher's Quay",
"3604_54285":"W2 - Clock Tower - WIT - Meagher's Quay",
"3604_54286":"W4 - Peter Street - Brownes Road",
"3604_54287":"W5 - Hospital - Oakwood",
"3608_51542":"975 - Cavan Institute - Longford, Outside Train Station",
"3610_42607":"129 - Roseberry Hill - Avondale Drive",
"3610_42688":"880 - Carlow, Carlow Coach Park - Naas (Post Office)",
"3610_42689":"883 - Athy (C Bar Leinster Street) - Dunnes Stores Newbridge",
"3610_49585":"821 - Newbridge (Opp Ryans Bar) - Sallins, Sallins Rail Station",
"3610_54638":"885 - Ballymore Eustace, Ballymore Inn Pub - Sallins, Sallins Rail Station",
"3614_54641":"SL10 - The Gateway, ATU Sligo (N16 Entrance) - Cavan, Cavan Bus Station",
"3616_54282":"180 - Clones - Dublin (Belfield)",
"3617_54642":"181 - Glendalough Visitor Centre - Dawson Street (Pink Shirt Shop)",
"3620_42764":"368 - Blackstoops, Old Dublin Road - New Ross (The Quay)",
"3620_42765":"369 - Enniscorthy, Enniscorthy - Bunclody (Market Square Northbound)",
"3620_48860":"389 - Riverchapel - Gorey",
"3620_49587":"384 - Wexford, Redmond Square - Moyne Upper, Meadowfields/The Moyne",
"3620_51584":"399 - Hook Head - New Ross",
"3620_51586":"392 - Wellingtonbridge - New Ross",
"3620_54643":"388 - Duncormick - Wexford (The Faythe)",
"3620_54644":"387 - Wexford - Rosslare",
"3623_54683":"1 - Sandymount - O'Connell Street - Santry",
"3623_54684":"4 - Monkstown Avenue - Harristown",
"3623_54685":"6 - Howth Dart Stn - Lower Abbey Street",
"3623_54686":"7 - Brides Glen Luas - Mountjoy Square",
"3623_54687":"7A - Loughlinstown - Mountjoy Sq",
"3623_54688":"7B - Shankhill - Mountjoy Square",
"3623_54689":"7D - Dalkey - Mountjoy Square",
"3623_54690":"7E - Mountjoy Square via Garage - Dalkey",
"3623_54691":"9 - Limekiln Avenue - Charelstown",
"3623_54692":"11 - Sandyford Ind Estate - Wadelai Pk",
"3623_54693":"13 - Grange Castle - Harristown",
"3623_54694":"14 - Dundrum Luas Stn - Ardlea Rd (Beaumont)",
"3623_54695":"61 - Whitechurch - Eden Quay",
"3623_54696":"15 - Ballycullen Road - Clongriffin",
"3623_54697":"16 - Ballinteer - Dublin Airport",
"3623_54698":"16D - Airport - Beaumont Village - Ballinteer not",
"3623_54699":"L53 - Liffey Valley - Adamstown Stn",
"3623_54700":"26 - Fonthill Rd (Liffey Valley) - Merrion Square",
"3623_54701":"27 - Jobstown - Clare Hall",
"3623_54702":"27X - Clare Hall - UCD Belfield",
"3623_54703":"77X - Citywest – UCD Belfield",
"3623_54704":"32X - UCD Belfield - Malahide",
"3623_54705":"33 - Balbriggan - Lower Abbey St",
"3623_54706":"33X - Skerries - Custom House Quay / St Stephen Green",
"3623_54707":"33D - Portrane - St Stephens Green",
"3623_54708":"33E - Lwr Abbey St - Portrane - Skerries",
"3623_54709":"37 - Blanchardstown Shopping Centre - Wilton Terrace",
"3623_54710":"38 - Damastown - Burlington Road",
"3623_54711":"38A - Damastown via Navan Rd - Burlington Rd",
"3623_54712":"38B - Damastown via Ballycoolin Ind Est - Burlington Rd",
"3623_54713":"70 - Dunboyne - Burlington Rd",
"3623_54714":"38D - IBM Damastown via N3 - Burlington Rd",
"3623_54715":"70D - Dunboyne - DCU",
"3623_54716":"39X - Ongar - Burlington Road",
"3623_54717":"39 - Ongar - Burlington Rd",
"3623_54718":"39A - Ongar - UCD Belfield",
"3623_54719":"40 - Charlestown Shopping Centre - Earlsfort Terrace",
"3623_54720":"40B - Toberburr - Parnel Street",
"3623_54721":"40E - Broombridge Luas - Tyrrelstown",
"3623_54722":"40D - Tyrrelstown - Parnell Street",
"3623_54723":"41 - Swords Manor - Lower Abbey Street",
"3623_54724":"41D - Swords Business Park - Lwr Abbey St",
"3623_54725":"41B - Rolestown - Lower Abbey Street",
"3623_54726":"41C - Swords Manor Via River Valley - Lower Abbey St",
"3623_54727":"41X - Swords - UCD Belfield",
"3623_54728":"42 - Sand's Hotel Portmarnock - Eden Quay",
"3623_54729":"43 - Talbot Street – Swords Business Park",
"3623_54730":"44 - Enniskerry - DCU",
"3623_54731":"44B - Glencullen - Dundrum Luas",
"3623_54732":"46A - Dun Laoghaire - Phoenix Park",
"3623_54733":"46E - Mountjoy Square - Blackrock Station",
"3623_54734":"47 - Poolbeg Street – Belarmine",
"3623_54735":"49 - Tallaght (The Square) - Pearse St",
"3623_54736":"51D - Aston Quay – Clondalkin",
"3623_54737":"52 - Intel - Ringsend",
"3623_54738":"27A - Blunden Drive - Eden Quay",
"3623_54739":"53 - Dublin Ferryport - Talbot St",
"3623_54740":"54A - Ellensborough - Pearse St",
"3623_54741":"56A - Tallaght - Ringsend Rd",
"3623_54742":"60 - Forbes Street - Tallaght",
"3623_54743":"65B - Poolbeg Street – Citywest",
"3623_54744":"65 - Poolbeg Street – Blessington/Ballymore",
"3623_54745":"69 - Rathcoole - Hawkins St",
"3623_54746":"69X - Rathcoole - Hawkins St",
"3623_54747":"68 - Newcastle / Greenogue Business Pk - Hawkins St",
"3623_54748":"68A - Hawkins Street – Bulfin Road",
"3623_54749":"77A - Citywest - Ringsend",
"3623_54750":"83 - Kimmage - Harristown",
"3623_54751":"83A - Stannaway Ave - Harristown Bus Garage",
"3623_54752":"84A - Bray Rail Station - Blackrock",
"3623_54753":"84 - Newcastle - Blackrock",
"3623_54754":"99 - Phoenix Park - Phoenix Park",
"3623_54755":"116 - Whitechurch - Parnell Square",
"3623_54756":"118 - Kilternan – Eden Quay",
"3623_54757":"120 - Ashtown Station - Parnell St",
"3623_54758":"122 - Drimnagh - Ashington",
"3623_54759":"123 - Marino - Walinstown (Kilnamanagh Rd)",
"3623_54760":"130 - Talbot Street – Castle Avenue",
"3623_54761":"140 - Palmerstown Park – Ballymun (Ikea)",
"3623_54762":"142 - Portmarnock - UCD (Belfield)",
"3623_54763":"84X - Hawkins Street – Newcastle / Kilcoole",
"3623_54764":"145 - Heuston Rail Station - Ballywaltrim",
"3623_54765":"150 - Rossmore - Hawkins Street",
"3623_54766":"151 - Foxborough (Balgaddy Rd) - Docklands (East Rd)",
"3623_54767":"155 - Bray - IKEA Ballymun",
"3623_54768":"P29 - Adamstown Stn - Ringsend",
"3623_54769":"X25 - Maynooth - Easton Rd - UCD",
"3623_54770":"X26 - Maynooth – Louisa Bridge – Leeson Street Lower",
"3623_54771":"X27 - Salesian College - Aghards Rd - UCD",
"3623_54772":"X28 - Salesian College - Celbridge Main St - UCD",
"3623_54773":"X30 - Dodsboro - UCD",
"3623_54774":"X31 - River Forest - Earlsfort Terrace",
"3623_54775":"X32 - Leixlip - Earlsfort Terrace",
"3623_54776":"C1 - Adamstown Stn - Sandymount",
"3623_54777":"C2 - Adamstown Stn - Sandymount",
"3623_54778":"C3 - Maynooth - Ringsend",
"3623_54779":"C4 - Maynooth - Ringsend",
"3623_54780":"C6 - Maynooth - Celbridge - Ringsend Night Service",
"3623_54781":"C5 - Maynooth - Ringsend Night Service",
"3623_54782":"G1 - Red Cow LUAS - Spencer Dock",
"3623_54783":"G2 - Liffey Valley SC - Spencer Dock",
"3623_54784":"H1 - Baldoyle - Lower Abbey Street",
"3623_54785":"H2 - Malahide - Lower Abbey Street",
"3623_54786":"H3 - Howth Summit - Lower Abbey Street",
"3623_54787":"N4 - Blanchardstown - Point Village",
"3623_54788":"15B - Merrion Square – Stocking Avenue",
"3623_54789":"15D - Merrion Square - Whitechurch",
"3623_54790":"15A - Merrion Square – Limekiln Avenue",
"3623_54791":"27B - Eden Quay – Harristown",
"3623_54792":"42D - Portmarnock - DCU",
"3623_54793":"L54 - River Forest - Red Cow Luas",
"3623_54794":"L58 - River Forest - Castletown - Hazelhatch Stn",
"3623_54795":"L59 - River Forest - Celbridge - Hazelhatch",
"3626_39030":"29n - Dublin City South, D'Olier Street - Baldoyle, Warrenhouse Road",
"3626_39031":"31n - Dublin City South, D'Olier Street - Howth, Outside Train Station",
"3626_39032":"33n - Dublin City South, Westmoreland Street - Balbriggan, Church",
"3626_39033":"42n - Dublin City South, D'Olier Street - Portmarnock, Strand Road (St.Anne's Estate)",
"3626_39034":"46n - Dublin City South, D'Olier Street - Dundrum, Outside Luas Station",
"3626_39035":"49n - Dublin City South, D'Olier Street - Kilnamanagh, Mayberry Road",
"3626_39036":"69n - Dublin City South, Westmoreland Street - Saggart, Citywest Golf Club",
"3626_39037":"70n - Dublin City South, Westmoreland Street - Dunboyne, Station Road, School",
"3626_39038":"77n - Dublin City South, D'Olier Street - Citywest, Westbrook Lawns",
"3626_39039":"7n - Dublin City South, D'Olier Street - Dún Laoghaire-Rathdown, Woodbrook Golf Club",
"3626_39040":"84n - Dublin City South, D'Olier Street - Charlesland Road (Seaborne View Apts)",
"3626_39041":"88n - Dublin City South, Westmoreland Street - Ashbourne, Ashbourne Kelly's",
"3627_37936":"740X - Wexford, Redmond Square - Dublin Airport",
"3627_40651":"340 - Wexford, Redmond Square - County Waterford, Whitfield Clinic",
"3627_40654":"740A - Gorey (Main St - Whitmore Jewellers) - Dublin Airport",
"3627_40655":"740 - Wexford, Redmond Square - Dublin Airport",
"3627_40656":"877 - Wexford, Redmond Square - Wexford, Redmond Square",
"3627_40658":"WX1 - Wexford, Redmond Square - Wexford, Redmond Square",
"3627_40659":"WX2 - Wexford, Redmond Square - Wexford, Redmond Square",
"3627_46527":"376 - Wexford, Redmond Square - Wexford, Redmond Square",
"3627_50536":"390 - Wexford, Redmond Square - Kilmore Quay",
"3634_42637":"339 - Kilrush, Aldi - Kilrush, Turk's Bar",
"3634_42654":"520 - Ardpatrick, Ardpatrick Community Centre - Ardpatrick, Ardpatrick Community Centre",
"3634_42672":"593 - Newcastle West, Dooley's SuperValu - Newcastle West, Dooley's SuperValu",
"3634_42673":"595 - Tarbert, Tarbert - Skagh (Limerick), Mid Western Regional Hospital",
"3634_46526":"337 - Ennis, Friars Walk - Kilrush, Turk's Bar",
"3634_48971":"335 - Ennis, Friars Walk - Kilrush, Turk's Bar",
"3634_54156":"318 - Limerick City - Ennis",
"3634_54637":"344 - Whitegate - Ennis",
"3634_54640":"521 - Newcastlewest - Charleville",
"3634_54835":"342 - Flagmount - Ennis",
"3636_54400":"2 - Dublin Airport - Gorey - Wexford",
"3636_54401":"4 - Dublin Airport – Waterford",
"3636_54402":"13 - Limerick – Adare - Listowel – Tralee",
"3636_54403":"14 - Limerick - Kerry Airport – Killarney",
"3636_54404":"22 - Dublin - Airport - Longford - Ballina",
"3636_54405":"23 - Dublin - Airport - Longford - Sligo",
"3636_54406":"30 - Dublin - Airport - Cavan - Donegal",
"3636_54407":"32 - Dublin Airport - Monaghan - Letterkenny",
"3636_54408":"40 - Rosslare - Waterford - Cork - Tralee",
"3636_54409":"51 - Cork - Limerick - Galway",
"3636_54410":"52 - Ballina - Castlebar - Galway",
"3636_54411":"55 - Limerick - Waterford",
"3636_54412":"64 - Galway Bus Station - Derry (Magee Campus Strand Road)",
"3636_54413":"65 - Galway - Athlone - Monaghan",
"3636_54414":"70 - Galway - Mullingar - Dundalk",
"3636_54415":"72 - Limerick - Birr - Athlone",
"3636_54416":"73 - Waterford - Athlone - Longford",
"3636_54417":"100 - Drogheda - Dundalk - Newry",
"3636_54418":"101 - Dublin - Airport - Drogheda",
"3636_54419":"103 - Dublin - Ashbourne - Ratoath",
"3636_54420":"B1 - Rail Station - Millfield Centre",
"3636_54421":"105 - Blanchardstown - Ashbourne - Ashbourne - Drogheda",
"3636_54422":"107 - Dublin - Navan - Nobber - Kingscourt",
"3636_54423":"108 - Dublin - Kells - Bailieboro",
"3636_54424":"109 - Dublin - Navan - Kells - Cavan",
"3636_54425":"111 - Wilton Tce - Trim - Granard - Cavan",
"3636_54426":"115 - Dublin - Mullingar",
"3636_54427":"131 - Bray - Wicklow",
"3636_54428":"132 - Dublin - Carnew - Bunclody",
"3636_54429":"133 - Dublin - Wicklow",
"3636_54430":"134 - Dorey's Forge - Dunsany - Navan",
"3636_54431":"135 - Scurloughstown - Navan",
"3636_54432":"136 - Ross Cross - Navan Shopping Centre",
"3636_54433":"160 - Dundalk - Ravensdale - Newry",
"3636_54434":"161 - Dundalk - Carlingford - Newry",
"3636_54435":"162 - Dundalk - Monaghan - Clones - Cavan",
"3636_54436":"167 - Dundalk - Louth - Ardee",
"3636_54437":"168 - Dundalk - Annagassan",
"3636_54439":"173 - Drogheda West St - Dominck St",
"3636_54440":"174 - Long Walk - Muirhevnamuir",
"3636_54441":"175 - Monaghan - Cootehill - Cavan",
"3636_54442":"182 - Drogheda - Collon - Ardee - Monaghan",
"3636_54443":"187 - Kells - Virginia - Ballyjamesduff",
"3636_54444":"190 - Drogheda - Navan - Trim",
"3636_54445":"201 - Lotabeg - Hollyhill - CIT - CUH",
"3636_54446":"202 - Ringmahon - City - Hollyhill (Apple)",
"3636_54447":"203 - Lehenaghmore - City Centre - Parklands",
"3636_54448":"205 - Train Station - St. Patrick St - CIT",
"3636_54449":"206 - Grange - Douglas - South Mall",
"3636_54450":"207 - Donnybrook - City Centre - City Centre - Glenheights",
"3636_54451":"208 - Lotabeg - Bishopstown - Curraheen",
"3636_54452":"209 - Patrick St - Audley Place - Lotamore",
"3636_54453":"212 - Kent Station - Blackrock - Mahon Point",
"3636_54454":"213 - Lapps Quay (P&R) - Black Ash",
"3636_54455":"214 - Glanmire - City Centre - CUH",
"3636_54456":"215 - Mahon Point - Ballinlough - Cloghroe",
"3636_54457":"216 - Mount Oval - City - Glasheen - CUH",
"3636_54458":"219 - Mahon - Douglas - CUH/ CIT (South Orb)",
"3636_54459":"220 - Ballincollig - Douglas - Carrigaline",
"3636_54460":"223 - Cork - Ringaskiddy - Haulbowline",
"3636_54461":"225 - Cork Airport - Haulbowline",
"3636_54462":"226 - Train Stn - Airport - Kinsale",
"3636_54463":"233 - Cork - Macroom",
"3636_54464":"235 - Cork - Rylane - Stuake",
"3636_54465":"236 - Cork - Bantry - Castletownbere",
"3636_54466":"237 - Cork - Skibereen - Goleen",
"3636_54467":"239 - Cork - Bandon",
"3636_54468":"240 - Cork - Cloyne - Ballycotton",
"3636_54469":"241 - Cork - Midelton - Whitegate",
"3636_54470":"243 - Cork - Newmarket/Doneraile/Charleville",
"3636_54471":"245 - Cork - Fermoy - Mitchelstown - Clonmel",
"3636_54472":"248 - Cork - Carrignavar/ Glenville",
"3636_54473":"257 - Macroom - Killarney",
"3636_54474":"258 - Macroom - Rylane",
"3636_54475":"259 - Macroom - Renaniree",
"3636_54476":"260 - Cork - Youghal - Ardmore",
"3636_54477":"261 - Cork - Midelton - Ballincurra",
"3636_54478":"270 - Killarney - Kenmare - Skibbereen",
"3636_54479":"271 - Tralee - Kerry Airport - Killarney",
"3636_54480":"272 - Tralee - Listowel - Tarbert",
"3636_54481":"273 - Tralee - Castlegregory - Cloghane",
"3636_54482":"274 - Tralee - Ballyheigue - Ballyduff",
"3636_54483":"275 - Tralee - Dingle",
"3636_54484":"278 - Tralee - Fenit",
"3636_54485":"279 - Killarney - Cahersiveen - Waterville",
"3636_54486":"284 - Killarney - Tralee",
"3636_54487":"301 - Westbury - Regional Hospital",
"3636_54488":"302 - City Centre - Caherdavin",
"3636_54489":"303 - O'Malley Park - City Centre - Pineview",
"3636_54490":"304 - UL - City Centre - Raheen",
"3636_54491":"305 - Lynwood Pk - City Centre - St Marys Pk",
"3636_54492":"306 - Brookfield - City Centre - Ballynanty",
"3636_54493":"313 - Limerick - Ardnacrusha",
"3636_54494":"314 - Limerick - Foynes - Glin - Ballybunion",
"3636_54495":"320 - Limerick - Charleville",
"3636_54496":"321 - Limerick - Rathkeale - Newcastlewest",
"3636_54497":"322 - Nenagh - Coolbawn - Lorrha",
"3636_54498":"323 - Limerick - Killaloe - Nenagh - Birr",
"3636_54499":"324 - Nenagh - Borrisokane - Kilbarron",
"3636_54500":"328 - Limerick - Mitchellstown",
"3636_54501":"329 - Limerick - Bruff - Kilfinane",
"3636_54502":"332 - Limerick - Dundrum",
"3636_54503":"333 - Limerick - Ennis - Corofin - Doonbeg",
"3636_54504":"336 - Limerick - Ennis - Kilrush - Kilkee",
"3636_54505":"341 - Shannon - Limerick - Cappamore",
"3636_54506":"343 - Limerick - Shannon Airport - Ennis",
"3636_54507":"345 - Limerick - Killaloe - Scariff",
"3636_54508":"346 - Limerick - Scarriff - Whitegate",
"3636_54509":"347 - Limerick - Tipperary",
"3636_54510":"348 - Ennis - Tulla - Scariff",
"3636_54511":"349 - Gort - Scariff - Feakle",
"3636_54512":"350 - Galway - Kinvara - Doolin - Ennis",
"3636_54513":"354 - Portlaw - Waterford - Dunmore East",
"3636_54514":"355 - Waterford - Cahir",
"3636_54515":"360 - Waterford - Tramore",
"3636_54516":"362 - Waterford - Dungarvan",
"3636_54517":"365 - Waterford - Thomastown",
"3636_54518":"366 - Waterford - Dungarvan - Lismore",
"3636_54519":"370 - Waterford - New Ross - Wexford",
"3636_54520":"371 - New Ross - Adamstown - Wexford",
"3636_54521":"372 - New Ross - Foulksmills - Wexford",
"3636_54522":"373 - New Ross - Fethard - Wexford",
"3636_54523":"374 - New Ross - Kilkenny",
"3636_54524":"375 - New Ross - Kiltealy - Enniscorthy",
"3636_54525":"377 - Wexford - Edermine - Enniscorthy",
"3636_54526":"378 - Wexford - Churchtown",
"3636_54527":"379 - Wexford - Curracloe - Gorey",
"3636_54528":"380 - Wexford - Crossabeg",
"3636_54529":"381 - Wexford - Blackhall",
"3636_54530":"382 - Adamstown - Wexford",
"3636_54531":"383 - Wexford - Kilmore Quay",
"3636_54532":"385 - Wexford - Rosslare Europort",
"3636_54533":"401 - Eyre Square - Salthill",
"3636_54534":"402 - Seacrest - Eyre Square - Merlin Park",
"3636_54535":"404 - Eyre Square - Newcastle",
"3636_54536":"405 - Rahoon - Eyre Square - Ballybane",
"3636_54537":"407 - Eyre Square - Bóthar an Chóiste",
"3636_54538":"409 - Eyre Square - Parkmore Ind. Est.",
"3636_54539":"417 - Galway - Corofin Cross - Tuam",
"3636_54540":"419 - Galway - Clifden",
"3636_54541":"420 - Swinford - Claremorris",
"3636_54542":"421 - Ballina - Pontoon - Castlebar",
"3636_54543":"422 - Headford - Castlebar",
"3636_54544":"423 - Westport - Clifden",
"3636_54545":"424 - Galway - Lettermullen",
"3636_54546":"425 - Galway - Roscommon - Longford",
"3636_54547":"429 - Galway - Tuam - Castlerea",
"3636_54548":"434 - Galway - Gort",
"3636_54549":"440 - Knock Airport - Westport - Dooagh",
"3636_54550":"442 - Charlestown - Castlebar - Westport",
"3636_54551":"443 - Ballina - Farragh Cross",
"3636_54552":"444 - Ballina - Dromore West",
"3636_54553":"445 - Ballina - Killala - Ballycastle",
"3636_54554":"446 - Ballina - Blacksod",
"3636_54555":"447 - Finea - Castlepollard - Mullingar",
"3636_54556":"448 - Shandonagh - Ballynacargy - Mullingar",
"3636_54557":"450 - Dooagh - Louisburgh",
"3636_54558":"451 - Ballina - Charlestown - Longford",
"3636_54559":"454 - Ballina - Laherdane - Westport",
"3636_54560":"455 - Ballina - Moygownagh - Crossmolina",
"3636_54561":"456 - Galway - Westport - Ballina",
"3636_54562":"457 - Castlrea - Ballintubber - Roscommon",
"3636_54563":"458 - Sligo - Enniscrone - Ballina",
"3636_54564":"460 - Sligo - Castlerea",
"3636_54565":"461 - Sligo - Roscommon - Athlone",
"3636_54566":"462 - Carrigallen - Ballinamore - Sligo",
"3636_54567":"463 - Carrigallen - Mohill - Longford",
"3636_54568":"464 - Carrigallen - Eniskillen",
"3636_54569":"465 - Carrigallen - Cavan",
"3636_54570":"466 - Athlone - Longford",
"3636_54571":"467 - Longford - Lanesboro - Roscommon",
"3636_54572":"468 - Strokestown - Carrick on Shannon",
"3636_54573":"469 - Sligo - Drumkeeran - Longford",
"3636_54574":"470 - Sligo - Glenfarne - Rossinver",
"3636_54575":"471 - Sligo - Ballymote - Riverstown",
"3636_54576":"S2 - Strandhill - Rosses Point",
"3636_54577":"474 - Sligo - Maugherow",
"3636_54578":"475 - Sligo - Collooney - Coolaney",
"3636_54579":"476 - Killavil - Ballymote - Tubbercurry",
"3636_54580":"S1 - Cartron - Sligo Town Centre - Cairns",
"3636_54581":"479 - Aclare - Coolaney - Sligo",
"3636_54582":"480 - Sligo - Donegal - Letterkenny - Derry",
"3636_54583":"483 - Ballyshannon - Sligo",
"3636_54584":"487 - Strabane - Raphoe - Letterkenny",
"3636_54585":"489 - Letterkenny - Carrigans - Strabane",
"3636_54586":"490 - Donegal - Killybegs - Glencolumbcille",
"3636_54587":"491 - Letterkenny - Ballybofey",
"3636_54588":"492 - Donegal - Glenties - Dungloe",
"3636_54589":"494 - Strabane - Ballybofey",
"3636_54590":"495 - Ballyshannon - Manorhamilton",
"3636_54591":"100X - Wilton Tce - Airport - Dundalk",
"3636_54592":"109A - DCU - Airport - Ratoath - Navan",
"3636_54593":"115C - Enfield - Killucan - Mullingar",
"3636_54594":"174A - Long Walk - Fatima",
"3636_54595":"174B - Long Walk - Bay Estate",
"3636_54596":"175A - Cavan - Clones - Monaghan",
"3636_54597":"182A - Drogheda - Hospital - Ardee",
"3636_54598":"N1 - Commons Road - Metges Road",
"3636_54599":"N2 - Aisling Place - Commons Road",
"3636_54600":"D4 - Southgate SC - Ballymakenny",
"3636_54601":"D5 - Colpe Road - Termonabbey",
"3636_54602":"209A - City Centre - Pouladuff - Connolly Rd",
"3636_54603":"215A - Mahon Point - Boreenmanna Rd - City",
"3636_54604":"304X - Raheen - University of Limerick",
"3636_54605":"207A - Merchants Quay - Glen Ave - Glenthorn",
"3636_54606":"CW1 - MSD - Tyndall College",
"3636_54607":"CW2 - Barrow Valley RPK - Business Park",
"3636_54608":"D1 - Drogheda - Laytown",
"3636_54609":"D2 - Drogheda - Coast Road - Laytown",
"3636_54610":"226X - Kinsale - UCC",
"3636_54611":"323X - Limerick - Birr - Athlone",
"3636_54612":"305A - CC - St.Marys Park - CC",
"3636_54613":"202A - Ringmahon - City - Hollyhill (Apple)",
"3636_54614":"225L - Carrigaline - NMCI",
"3636_54615":"425A - Galway - Monivea - Mountbellew",
"3636_54616":"360A - Waterford - WIT - Tramore",
"3636_54617":"279A - Waterville - Caherciveen - Killarney",
"3636_54618":"103X - Dublin - Ashbourne - Duleek - Navan",
"3636_54619":"105X - UCD - M3 - Ratoath - Ashbourne",
"3636_54620":"220X - Ovens - Carrigaline - Crosshaven",
"3636_54621":"343X - Limerick - Ennis",
"3636_54622":"101X - Wilton Tce - Drogheda - Termon Abbey",
"3636_54623":"109B - Dublin - Dunshaughlin - Trim",
"3636_54624":"245X - Busaras - Fermoy - Cork",
"3636_54625":"109X - Dublin - Kells - Cavan Hourly Express",
"3636_54626":"111A - Delvin - Granard - Cavan",
"3636_54627":"111X - Dublin express - Athboy - Clonmellon",
"3636_54628":"X30 - Dublin - Donegal Express",
"3636_54629":"223X - South Mall - Haulbowline",
"3636_54630":"X32 - Dublin - Letterkenny",
"3636_54631":"A1 - Bellanamulla - Station - Creggan",
"3636_54632":"A2 - Bellanamulla - Station - Creggan",
"3636_54633":"304A - Raheen - Bus Station - UL",
"3636_54634":"NX - Dublin Wilton Terrace - Navan",
"3636_54645":"170 - Cavan - Dundalk",
"3637_42625":"277 - Dingle, Dingle - Dunquin, Kruger's Bar",
"3637_42626":"283 - Currans Community Centre - Tralee, Denny Street",
"3637_42627":"285 - Killorglin, Killorglin - Waterville, Waterville",
"3637_42628":"287a - Knightstown - Cahersiveen, Cahersiveen",
"3637_42629":"287b - Ballinskelligs, Ballinskelligs Community Hall - Cahersiveen, Cahersiveen",
"3637_42724":"R73 - Dingle, Dingle - Dingle, Dingle",
"3637_42725":"R74 - Dingle, Dingle - Anascaul, O'Donnell's Shop",
"3637_51589":"281 - Waterville - Kenmare",
"3637_51591":"280 - Waterville - Killarney",
"3637_54856":"274 - Tarbert - Tralee",
"3638_42653":"R4995 - Ferbane, Ferbane - Tullamore, Tullamore",
"3638_42661":"558 - Mountmellick, MDA - Esker Hills, Portlaoise Leisure Centre",
"3638_42679":"823 - Chesterfield, Woodlawn Villas - Portlaoise, Kilminchy (Laurel Court)",
"3638_42680":"828 - Main Street, Cashel - Midland Regional Hospital",
"3638_42682":"839 - Borris in Ossory - Mount Lucas, Mount Lucas Training Centre",
"3638_42686":"858 - Semple Stadium, Thurles Train Station - Hospital",
"3638_48541":"840 - Banagher, Library - Tullamore (Offaly), O'Connor Square",
"3638_50535":"822 - Mountrath - Carlow",
"3638_54288":"834 - Roscrea, Church - Kilminchy Avenue",
"3638_54858":"850 - Athlone - Thurles",
"3642_42638":"358 - Ribh Road (Opp Ard na Gri) - Waterford, Clock Tower The Quay",
"3642_42641":"364 - Tallow - Fermoy, Fermoy",
"3642_42643":"367 - Tramore - Dungarvan (Davitts Quay)",
"3642_46279":"361 - Ardmore (Opp School) - Dungarvan (Davitts Quay)",
"3642_51588":"363 - Tallow - Dungarvan (Davitts Quay)",
"3642_54280":"667 - Dungarvan - Waterford City",
"3642_54859":"356 - Ballinroad - Clonmel",
"3643_54862":"rail - Belfast - Dublin",
"3643_54863":"rail - Dublin - Belfast",
"3643_54864":"InterCity - Dublin - Cork",
"3643_54866":"rail - Dublin - Tralee",
"3643_54868":"rail - Dublin - Limerick",
"3643_54870":"rail - Limerick - Galway",
"3643_54871":"rail - Waterford - Limerick",
"3643_54872":"rail - Limerick - Ballybrophy",
"3643_54875":"rail - Dublin - Waterford",
"3643_54878":"rail - Dublin - Europort",
"3643_54880":"rail - Dublin - Galway",
"3643_54881":"rail - Dublin - Westport",
"3643_54884":"rail - Dublin - Sligo",
"3643_54886":"Commuter - Dublin - Portlaoise",
"3643_54887":"rail - Dublin - Maynooth",
"3643_54888":"rail - Mallow - Cobh",
"3643_54889":"rail - Dublin - Drogheda/Dundalk",
"3643_54890":"DART - Bray - Howth",
"3643_54896":"rail - Dublin - Limerick via Nenagh",
"3644_40500":"251X - Galway City, Coach Station (Fairgreen Rd) - Cork, St. Patricks Quay",
"3644_40501":"251 - Galway City, Coach Station (Fairgreen Rd) - Cork Airport",
"3644_40502":"660 - Galway Coach Station - Dublin (Batchelors Walk)",
"3644_40504":"760 - Galway Coach Station - Dublin Airport",
"3644_40505":"761 - Galway Coach Station - Dublin Airport",
"3644_40506":"763X - Creagh (Opp Dubarry) - University Rd (Cathedral)",
"3644_40507":"763 - Galway City, Coach Station (Fairgreen Rd) - Dublin Airport",
"3644_43372":"923 - Galway City, Coach Station (Fairgreen Rd) - Olivers",
"3644_54796":"721 - Castlebar - Dublin Airport",
"3645_40503":"712X - Limerick City - Dublin Airport",
"3646_54115":"171 - Shercock - Dundalk",
"3646_54797":"176 - Cavan - Monaghan"
}


export const fetchAndProcessBusData = async () => {
  try {
    const response = await fetch(BUS_API_URL);
    const responseBody = await response.json();
    const newBusData = [];

    for (const aBus in responseBody.entity) {
      if (responseBody.entity[aBus]['vehicle']['trip']['route_id'] in routes) {
        const latitude = responseBody.entity[aBus]['vehicle']['position']['latitude'];
        const longitude = responseBody.entity[aBus]['vehicle']['position']['longitude'];
        const x = longitude;
        const y = latitude;
        const bearing = 0;
        const busData = {
          x: x,
          y: y,
          bearing: bearing,
          Desc: routes[responseBody.entity[aBus]['vehicle']['trip']['route_id']],
          Route: routes[responseBody.entity[aBus]['vehicle']['trip']['route_id']].substring(0, 3),
        };
        newBusData.push(busData);
      }
    }

    return newBusData;
  } catch (error) {
    console.error('Error fetching data:', error);
    return [];
  }
};
