import React, { useRef, useEffect,useState } from 'react';
import mapboxgl from 'mapbox-gl';

import './Map.css';
import DataViewer from './components/DataViewer';
import DataTable from './components/DataTable';

import { sparch } from './components/spanish_arch'; // Import the function
import { home } from './components/home'; // Import the function
import {config } from './components/layer_configs'; // Import the layer configuration functions
import {model_config } from './components/model_configs'; // Import the layer configuration functions
import {fetchAndProcessBusData} from "./components/NTA_dataservice"
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import "./mapbox-gl-geocoder.css";
import add3DModelToMap from './components/addModelToMap';




mapboxgl.accessToken =
  'pk.eyJ1Ijoib2pzbGV2aW4iLCJhIjoiY2xtNjczOGFpMXV6NzNrcDlhZGV1ODgwMCJ9.QGvXxxQJKsrRGIbIOOUGlQ';

const Map = () => {
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null); 
  const [busLayers, setBusLayers] = useState([]);
  const [dataTableData,setDataTableData]=useState([]);
  const [dataTableName,setDataTableName]=useState("")
  const [dataTableLayer,setDataTableLayer]=useState(null)
  const [select_object_table,setSelectedObject]=useState("")
  const [selectedFeature, setSelectedFeature] = useState(null);
  const [highlightFeatures, setHighlightFeatures] = useState(false);
  const [showLegend, setShowLegend] = useState(true);
  
  const buttonsAdded =useRef(false)

  function isNumericString(value) {
    return !isNaN(value) && !isNaN(parseFloat(value));
  }


  const toggleHighlighting = () => {

    console.log("toggle",highlightFeatures)
    setHighlightFeatures(!highlightFeatures);
    
  };
  const toggleLegend = () => {

    console.log("toggle",showLegend)
    const legend = document.getElementById('legend');
    const legendDescription = document.getElementById('legend-description');
    console.log(legendDescription.textContent)
    if (!showLegend){
      legend.style.display="block"
      legendDescription.style.display="block"
    }
    else{
      legend.style.display="none"
      legendDescription.style.display="none"
    }
    setShowLegend(!showLegend);
  };

  const handleFeatureClick = (feature) => {
    setSelectedFeature(feature);
  };
  const handleCloseDataViewer = () => {
    setSelectedFeature(null);
    if (typeof mapRef.current.getSource('selected_item') !== "undefined" ){   
      console.log("remove")    
      try{ 
      mapRef.current.removeLayer('selected_item')
      mapRef.current.removeSource('selected_item');   
      }
      catch{
        console.log('no selected_item layer')
      }
  }

  };

  const handleCloseDataTable = () => {
    setDataTableData([])
    try{
      mapRef.current.removeLayer('selected_item')
    }
    catch{
      console.log("no layer to remove")
    }

  };

  const handleOpenDataTable = async (layer,layer_name) => {
    try{
      mapRef.current.removeLayer('selected_item')
    }
    catch{
      console.log("no layer to remove")
    }
    console.log(layer_name)
    if (layer_name==="Bus Locations"){
      console.log("hello")
      setDataTableData(busLayers);
      setDataTableName(layer_name+" "+busLayers.length+" Records      ")
    }
    else{
      try {
        
        const response = await fetch(layer); // Assuming 'layer' is a URL to fetch data from
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        
        const data = await response.json(); // Parse the response as JSON
    
        const list_of_recs=[]
        for (const obj of data.features) {
          list_of_recs.push(obj['properties'])
        }
        // Update the state with the fetched data
        setDataTableData(list_of_recs);
        setDataTableName(layer_name+" "+list_of_recs.length+" Records      ")
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  };

  const handleFilterApplied=(filters) =>{

  
    console.log(filters)
    const list_of_mapbox_filters=[]

    try{
      mapRef.current.removeLayer('selected_item')
    }
    catch{
      console.log("no layer to remove")
    }

    for (let a_key in filters){
      console.log(filters[a_key])
      const list_filters=[]
      if ('operator' in filters[a_key]){
        console.log("two conditions")
        list_filters.push(filters[a_key]['condition1'])
        list_filters.push(filters[a_key]['condition2'])
      }
      else{
        list_filters.push(filters[a_key])
      }
      console.log(list_filters)
      list_filters.forEach(a_filter => {
        console.log(a_filter)
        if (a_filter['filterType']==='set'){
          console.log('set filter')
          list_of_mapbox_filters.push(['match',['to-string',["get",a_key]],a_filter['values'],true,false])
        }
        else if (a_filter['type']==='contains'){
          list_of_mapbox_filters.push(['in',["downcase",a_filter['filter']],  ["downcase",['string', ['coalesce',['get', a_key],'']]]])
        }
        else if (a_filter['type']==='notContains'){
          list_of_mapbox_filters.push(['!',['in',["downcase",a_filter['filter']], ["downcase",['string', ['coalesce', ['get', a_key],'']]]]])
        }
        else if (a_filter['type']==='equals'){
          if (isNumericString(a_filter['filter'])){
            list_of_mapbox_filters.push(['==',['to-number',['get',a_key]],a_filter['filter']])
          }
          else{
            list_of_mapbox_filters.push(['==',["downcase",a_key],["downcase",a_filter['filter']]])
          }
        }
        else if (a_filter['type']==='notEqual'){
          list_of_mapbox_filters.push(['!=',["downcase",a_key],["downcase",a_filter['filter']]])
        }
        else if (a_filter['type']==='startsWith'){

          list_of_mapbox_filters.push(['==',["downcase",['slice',['get',a_key],0,['length',a_filter['filter']]]],["downcase",a_filter['filter']]])

        }
        else if (a_filter['type']==='endsWith'){
          list_of_mapbox_filters.push(['==',["downcase",['slice', ['get', a_key], ['-', ['length', ['get', a_key]], ['length', a_filter['filter']]], ['length', ['get', a_key]]]],["downcase",a_filter['filter']]])
        }
        else if (a_filter['type']==='blank'){
          list_of_mapbox_filters.push(['!',['has', a_key]])
        }
        else if (a_filter['type']==='notBlank'){
          list_of_mapbox_filters.push(['has', a_key])
        }
        else if (a_filter['type']==='greaterThan'){
          list_of_mapbox_filters.push(['>', ['to-number',['get',a_key]],a_filter['filter']])
        }
        else if (a_filter['type']==='lessThan'){
          list_of_mapbox_filters.push(['<', ['to-number',['get',a_key]],a_filter['filter']])
        }
        else if (a_filter['type']==='greaterThanOrEqual'){
          list_of_mapbox_filters.push(['>=', ['to-number',['get',a_key]],a_filter['filter']])
        }
        else if (a_filter['type']==='lessThanOrEqual'){
          list_of_mapbox_filters.push(['<=', ['to-number',['get',a_key]],a_filter['filter']])
        }
        else if (a_filter['type']==='inRange'){
          list_of_mapbox_filters.push(['all',
          ['>=', ['to-number',['get',a_key]], a_filter['filter']],
          ['<=', ['to-number',['get',a_key]], a_filter['filterTo']]
        ])
        }
      })
    }

    console.log(list_of_mapbox_filters)
    list_of_mapbox_filters.unshift('all')
    const layer_type=mapRef.current.getLayer(dataTableLayer).type
    if (layer_type==='circle'){
      mapRef.current.addLayer({
          id: 'selected_item', // Give it a unique ID
          type: 'circle',
          source: dataTableLayer,
          paint: {
            'circle-color': "red",
            'circle-radius':5,
    
              // Get `fill-extrusion-height` from the source `height` property.
              
              // Make extrusions slightly opaque to see through indoor walls.
              'circle-stroke-width':1,
              'circle-stroke-color':"black"
        },
        filter: list_of_mapbox_filters // Apply your filter criteria
      });
      
    }
    else if (layer_type==='fill' || layer_type==='fill-extrusion' ){
      mapRef.current.addLayer({
        id: 'selected_item', // Give it a unique ID
        type: 'fill',
        source: dataTableLayer,
        paint: {
          'fill-color': "red",

            // Get `fill-extrusion-height` from the source `height` property.
            
            // Make extrusions slightly opaque to see through indoor walls.
            'fill-opacity': 1 ,
            'fill-outline-color':"black"
        },
        filter: list_of_mapbox_filters // Apply your filter criteria
      });
    }
  }

  const handleRowSelect =(record) =>{
    record=record.data
    const layer_type=mapRef.current.getLayer(dataTableLayer).type
    const list_of_mapbox_filters=[]

    for (let a_key in record){
      if (record[a_key] !==null){

        if (isNumericString(record[a_key])){
          list_of_mapbox_filters.push(['==',['to-number',['get',a_key]],['to-number',record[a_key]]])
        }
        else{
          list_of_mapbox_filters.push(['==',['get',a_key],record[a_key]])
        }
        
      }
    }
    list_of_mapbox_filters.unshift('all')
    
    if (mapRef.current.getLayer('selected_item')!== undefined){
      mapRef.current.removeLayer('selected_item')
    }

    if (layer_type==='circle'){
      mapRef.current.addLayer({
        id: 'selected_item', // Give it a unique ID
        type: 'circle',
        source: dataTableLayer,
        paint: {
          'circle-color': "red",
          'circle-radius':5,
  
            // Get `fill-extrusion-height` from the source `height` property.
            
            // Make extrusions slightly opaque to see through indoor walls.
            'circle-stroke-width':1,
            'circle-stroke-color':"black"
        },
        filter: list_of_mapbox_filters // Apply your filter criteria
      });
    
    }
    else if (layer_type==='fill' || layer_type==='fill-extrusion' ){
      mapRef.current.addLayer({
        id: 'selected_item', // Give it a unique ID
        type: 'fill',
        source: dataTableLayer,
        paint: {
          'fill-color': "red",

            // Get `fill-extrusion-height` from the source `height` property.
            
            // Make extrusions slightly opaque to see through indoor walls.
            'fill-opacity': 1 ,
            'fill-outline-color':"black"
        },
        filter: list_of_mapbox_filters // Apply your filter criteria
      });
    }  
  }

  const [isOpen, setIsOpen] = useState(false);
  
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  //update bus data
  useEffect(() => {
    fetchAndProcessBusData().then((newBusData) => {
      if (newBusData.length>0){
      setBusLayers(newBusData);

      }
      console.log(newBusData);
    });

    // Fetch and update bus data every 30 seconds
    const fetchInterval = setInterval(() => {
      fetchAndProcessBusData().then((newBusData) => {
        if (newBusData.length>0){
          setBusLayers(newBusData)
        }
        console.log(newBusData);

      });
    }, 30000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(fetchInterval);
  }, []);

  // render bus data
  useEffect(()=>{
    console.log("bus layer render")
  
   const temp_bus_features=[]
      for (const a_bus in busLayers){
        const a_bus_feature={
          // feature for Mapbox DC
          'type': 'Feature',
          'geometry': {
            'type': 'Point',
            'coordinates': [
              busLayers[a_bus].x,busLayers[a_bus].y
            ]
          },
          'properties': {
            'Description': busLayers[a_bus].Desc,
            'Route':busLayers[a_bus].Route
          }
        }
        temp_bus_features.push(a_bus_feature)
        
      }
     
     if(mapRef.current!==null){
      if (mapRef.current.style!==undefined){
        if (mapRef.current.getSource('points')!== undefined){
          const source = mapRef.current.getSource('points');
          console.log(source)
          source.setData({
          'type': 'FeatureCollection',
          'features': temp_bus_features
        })
        mapRef.current.triggerRepaint()
        mapRef.current.setLayerZoomRange('points', 0, 24);
        console.log(temp_bus_features)
        }
      }
     }
     console.log(mapRef)
    

  }, [busLayers,mapRef])

  
  // Initialize map when component mounts
  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      attributionControl: false,
      style: "mapbox://styles/mapbox/satellite-streets-v12",
      center: [-9.054120953609672, 53.27002140176011],
      pitch:35,
      //maxPitch:45,
      zoom: 16,
      light: {
        "anchor": "viewport",
        "color": "white",
        "intensity": 0.4
      }
    });
    
    mapRef.current = map;
    map.addControl(
      new MapboxGeocoder({
          accessToken: mapboxgl.accessToken,
          mapboxgl: mapboxgl,
          countries: 'ie',          
      })
  );

    map.addControl(new mapboxgl.NavigationControl());
    map.addControl(new mapboxgl.GeolocateControl());
   
    // change cursor to pointer when user hovers over a clickable feature
    map.on('mouseenter', e => {
      if (e.features.length) {
        map.getCanvas().style.cursor = 'pointer';
      }
    });

    // reset cursor to default when user is no longer hovering over a clickable feature
    map.on('mouseleave', () => {
      map.getCanvas().style.cursor = '';
    });

    // add tooltip when users mouse move over a point
    map.on('mousemove', e => {
      
    });



    

    /* //popup on click
    map.on('mousedown',e=>{
      const features = map.queryRenderedFeatures(e.point);
      if (features.length) {
        const feature = features[0];


        // Create tooltip node
        const tooltipNode = document.createElement('div');
        const root = createRoot(tooltipNode); // createRoot(container!) if you use TypeScript
        root.render(<Tooltip feature={feature} />);

        // Set tooltip on map
        tooltipRef.current
          .setLngLat(e.lngLat)
          .setDOMContent(tooltipNode)
          .addTo(map);
      }

    });
    */


    map.on('style.load', () => {
  
      const customLayer = sparch();
      map.addLayer(customLayer);
      map.setLayerZoomRange('3d-model', 17, 24);
      const customLayer2 = home();
      map.addLayer(customLayer2);
      map.setLayerZoomRange('3d-garden', 17, 24);

      let models3d=model_config();
      for (let a_model in models3d){
        console.log(a_model)
        const customLayer3 = add3DModelToMap(map,a_model, models3d[a_model]['modelPath'], models3d[a_model]['modelPosition'], models3d[a_model]['modelRotation'], models3d[a_model]['modelAltitude'], models3d[a_model]['modelScale']);
        console.log(customLayer3)
        map.addLayer(customLayer3)
        map.setLayerZoomRange(a_model, 17, 24);
      }
      
    

    


    })

    // add bus layer, and layer config layers
    map.on('load', () => {
      map.loadImage(
        'https://docs.mapbox.com/mapbox-gl-js/assets/custom_marker.png',
        (error, image) => {
          if (error) throw error;
          map.addImage('custom-marker', image);
          // Add a GeoJSON source with 2 points
          map.addSource('points', {
            'type': 'geojson',
            'data': {
              'type': 'FeatureCollection',
              'features': []
            }
          });
  
          // Add a symbol layer
          map.addLayer({
            'id': 'points',
            'type': 'symbol',
            'source': 'points',
            'metadata':{'desc':'Bus Locations', "layer_group":'Bus Locations'},
            'visibility': 'visible',
            'layout': {
           
                'visibility': 'none',
        
              'icon-image': 'custom-marker',
              'icon-allow-overlap':true,
              // get the title name from the source's "title" property
              'text-allow-overlap': true,
              'text-field': ['get', 'Route'],
              'text-font': [
                'Open Sans Semibold',
                'Arial Unicode MS Bold'
              ],
              'text-offset': [0, 1.25],
              'text-anchor': 'top'
            },
              "paint": {
                'text-color':'black',
                'text-halo-color': 'white',
                'text-halo-width': 1
              }
          
          });
       
        }
      );

    

      const layers = config()
      console.log(layers)

     

      for (const layerName in layers) {

        const layerConfig = layers[layerName]['config'];
        const sourcePath = layers[layerName]['source'];

        


        // Add the source
        map.addSource(layerConfig['source'], {
          type: 'geojson',
          data: sourcePath,
        });

        // Add the layer
        map.addLayer(layerConfig);
        
        const subLayer = map.getLayer(layerConfig['id']);
        /*
        const style_layers=document.getElementById('layer')
        const style_option = document.createElement('option')
        style_option.value=layerConfig['id']
        console.log(subLayer?.metadata?.desc)
        style_option.textContent=subLayer?.metadata?.desc
        style_layers.appendChild(style_option)

        
        */
        

      } 

      /*
      const fieldset=document.getElementById('layer_set')
      fieldset.addEventListener('change',()=>{
        
        console.log(document.getElementById('layer').value)
        console.log(map.getLayer(document.getElementById('layer').value))
        let features=map.querySourceFeatures(document.getElementById('layer').value)
        const allKeys = new Set();
        const key_typs={}
        for (let a_feature in features){

          let feature_props=features[a_feature].properties
          for (let a_key in feature_props){

            let is_num=isNumericString(feature_props[a_key])
           
            
            if (!(allKeys.has(a_key))){
              if (is_num){
                key_typs[a_key]={'is_num':is_num, 'min':Number(feature_props[a_key]),'max':Number(feature_props[a_key])}
              }
              else{
                key_typs[a_key]={'is_num':is_num}
              }
              allKeys.add(a_key);
            }

            if (is_num){
              if (Number(feature_props[a_key])<Number(key_typs[a_key]['min'])){
                key_typs[a_key]['min']=Number(feature_props[a_key])
              }
              if (Number(feature_props[a_key])>Number(key_typs[a_key]['max'])){
                key_typs[a_key]['max']=Number(feature_props[a_key])
              }
       
            }

          };
        };

        const style_field=document.getElementById('field')
        style_field.innerHTML = ''
        allKeys.forEach(key => {
          
          
          const field_option = document.createElement('option')
          field_option.value=key
          field_option.textContent=key
          style_field.appendChild(field_option)
          
          
          
        })
        console.log(key_typs)
        

        
        //field_option.textContent=subLayer?.metadata?.desc
        //style_field.appendChild(field_option)
      })
      
      const swatches = document.getElementById('swatches');
        const layer = document.getElementById('layer');
        const colors = [
            '#ffffcc',
            '#a1dab4',
            '#41b6c4',
            '#2c7fb8',
            '#253494',
            '#fed976',
            '#feb24c',
            '#fd8d3c',
            '#f03b20',
            '#bd0026'
        ];

        for (const color of colors) {
            const swatch = document.createElement('button');
            swatch.style.backgroundColor = color;
            swatch.addEventListener('click', () => {
                const map_layer=map.getLayer(layer.value)
                console.log(map_layer)
                if (map_layer.type==='fill-extrusion'){
                map.setPaintProperty(layer.value, 'fill-extrusion-color', color);
                }
                else if (map_layer.type==='fill'){
                  map.setPaintProperty(layer.value, 'fill-color', color);
                  }
                else if   (map_layer.type==='circle'){
                    map.setPaintProperty(layer.value, 'circle-color', color);
                }
            });
            swatches.appendChild(swatch);
        }
        */

    })


    
    map.addControl(new mapboxgl.AttributionControl({
      customAttribution: '<a href="https://www.mapbox.com/about/maps/" target="_blank">' +
          '<img src="SFI.png" style="width: 300px; height: 60px;">' +
          '</a>'
  }));

    map.on('idle', () => {
      if (buttonsAdded.current) return;
    
      // Enumerate ids of the layers.
      const toggleableLayerIds = [];
      const layers = config();
      const layerGroups = {};
    
      layers['points']={'config':{
        'id': 'points',
        'type': 'symbol',
        'source': 'points',
        'metadata':{'desc':'Bus Locations', "layer_group":'Bus Locations'},
        'visibility': 'visible',
        'layout': {
          'icon-image': 'custom-marker',
          'icon-allow-overlap':true,
          // get the title name from the source's "title" property
          'text-allow-overlap': true,
          'text-field': ['get', 'Route'],
          'text-font': [
            'Open Sans Semibold',
            'Arial Unicode MS Bold'
          ],
          'text-offset': [0, 1.25],
          'text-anchor': 'top'
        },
          "paint": {
            'text-color':'black',
            'text-halo-color': 'white',
            'text-halo-width': 1
          }
      
      }}

      for (const layer in layers) {
        const meta_name = layers[layer]['config']['id'];
        toggleableLayerIds.push(meta_name);
        const layerConfig = layers[layer]['config'];
        const layerGroup = layerConfig['metadata']['layer_group'];

        
        if (!layerGroup) {
          // If layer_group is not specified, create a new group for this layer
          layerGroups[layer] = [layer];
        } else {
          // If layer_group is specified, add the layer to the existing group or create a new group
          if (layerGroups[layerGroup]) {
            layerGroups[layerGroup].push(layer);
          } else {
            layerGroups[layerGroup] = [layer];
          }
        }
      }
    
      for (const groupName in layerGroups) {
        const groupContainer = document.createElement('div');
        groupContainer.className = 'group-container';
    
        // Create the big button to toggle the group visibility
        const groupButton = document.createElement('button');
        groupButton.id = groupName + '-group'; // Add a unique ID for the group button
        groupButton.href = '#';
        groupButton.textContent = groupName;
        groupButton.className = 'group-button';
    
        const isGroupActive = layerGroups[groupName].every((subLayerId) => {
          const visibility = map.getLayoutProperty(subLayerId, 'visibility');
          return visibility === 'visible';
        });
        groupButton.classList.toggle('active', isGroupActive);
    
        // Create the small button to toggle the sub-layer buttons
        const subLayerButton = document.createElement('button');
        subLayerButton.textContent = '▶'; // Unicode for a right-pointing arrow
        subLayerButton.className = 'sub-layer-button';
    
        const dropdownMenu = document.createElement('div');
        dropdownMenu.className = 'dropdown-menu';
        dropdownMenu.style.display = 'none'; // Initially hide the dropdown menu
    
        // Function to toggle group and sub-layer visibility
        
    
        // Add a click event listener to the group button
        groupButton.addEventListener('click', () => {
          const menu = subLayerButton.nextElementSibling;
          if (menu.style.display === 'block') {
            menu.style.display = 'none';
          } else {
            menu.style.display = 'block';
          }
        });
    
        // Create toggle buttons for sub-layers within the group
        for (const subLayerId of layerGroups[groupName]) {
          const link = document.createElement('a');
          link.id = subLayerId;
          link.href = '#';
          const subLayer = map.getLayer(subLayerId);


          link.textContent = subLayer?.metadata?.desc;
          const visibility = map.getLayoutProperty(subLayerId, 'visibility');
          link.className = visibility === 'visible' ? 'active' : 'inactive';
        
          // Show or hide sub-layer when the toggle is clicked
          link.onclick = function (e) {
            const clickedLayer = this.id;
            e.preventDefault();
            e.stopPropagation();
            
            
            
            let text_desc;
            let layers=[];
            let colors=[];

            console.log(map.getLayer(clickedLayer))
            const layer_type=map.getLayer(clickedLayer).type
            const legend = document.getElementById('legend');
            const legendDescription = document.getElementById('legend-description');
            try{
              if (layer_type==='fill'){
                text_desc=map.getLayer(clickedLayer).paint._values['fill-color'].value._styleExpression.expression.input.args[0].args[0].value
                layers=map.getLayer(clickedLayer).paint._values['fill-color'].value._styleExpression.expression.labels
                colors=map.getLayer(clickedLayer).paint._values['fill-color'].value._styleExpression.expression.outputs
              }
              else if (layer_type==='circle'){
                text_desc=map.getLayer(clickedLayer).paint._values['circle-color'].value._styleExpression.expression.input.args[0].args[0].value
                layers=map.getLayer(clickedLayer).paint._values['circle-color'].value._styleExpression.expression.labels
                colors=map.getLayer(clickedLayer).paint._values['circle-color'].value._styleExpression.expression.outputs
              }
              else if (layer_type==='fill-extrusion'){
                if ('branches' in map.getLayer(clickedLayer).paint._values['fill-extrusion-color'].value._styleExpression.expression){
                  map.getLayer(clickedLayer).paint._values['fill-extrusion-color'].value._styleExpression.expression.branches.forEach((branch,i)=>{
                    text_desc=branch[0]['lhs']['args'][0]['value']
                
                    layers.push(branch[0]['rhs']['value'])
                    colors.push(branch[1])
                  })
                  
                }
                else{
                text_desc=map.getLayer(clickedLayer).paint._values['fill-extrusion-color'].value._styleExpression.expression.input.args[0].args[0].value
                layers=map.getLayer(clickedLayer).paint._values['fill-extrusion-color'].value._styleExpression.expression.labels
                colors=map.getLayer(clickedLayer).paint._values['fill-extrusion-color'].value._styleExpression.expression.outputs
                }
              }
              
              console.log(layers,colors)
        
              
              legendDescription.textContent =text_desc ;
            
              // create legend
              
              legend.innerHTML = ''
              
              layers.forEach((layer, i) => {
                const color = colors[i];
                const item = document.createElement('div');
                const key = document.createElement('span');
                console.log(color)
                key.className = 'legend-key';

                var r = Math.round(color['value']['r']* 255).toString(16).padStart(2, '0');
                var g = Math.round(color['value']['g'] * 255).toString(16).padStart(2, '0');
                var b = Math.round(color['value']['b'] * 255).toString(16).padStart(2, '0');
                var hexColor = '#' + r + g + b;
                key.style.backgroundColor = hexColor
                
                const value = document.createElement('span');
                value.innerHTML = `${layer}`;
                item.appendChild(key);
                item.appendChild(value);
                legend.appendChild(item);

                if (showLegend){
                  legend.style.display="block"
                  legendDescription.style.display="block"
                }
              });
            }
            catch{
              legend.style.display="none"
              legendDescription.style.display="none"
              console.log("no legend available")
            }
            
    
            
            const visibility = map.getLayoutProperty(clickedLayer, 'visibility');
    
            // Toggle sub-layer visibility by changing the layout object's visibility property
            if (visibility === 'visible') {
              map.setLayoutProperty(clickedLayer, 'visibility', 'none');
              this.className = 'inactive';
            } else {
              this.className = 'active';
              map.setLayoutProperty(clickedLayer, 'visibility', 'visible');
            }
       
            const title =map.getLayer(clickedLayer).metadata['desc']
 
            setDataTableLayer(clickedLayer)
            handleCloseDataTable()
            handleOpenDataTable(map.getSource(clickedLayer).serialize().data,title);
  
          };
          
          dropdownMenu.appendChild(link);
        }
    
        // Add an event listener to toggle the dropdown menu visibility
        subLayerButton.addEventListener('click', () => {
          const menu = subLayerButton.nextElementSibling;
          if (menu.style.display === 'block') {
            menu.style.display = 'none';
          } else {
            menu.style.display = 'block';
          }
        });
    
        // Append the group button, sub-layer button, and dropdown menu to the group container
        groupContainer.appendChild(groupButton);
        groupContainer.appendChild(subLayerButton);
        groupContainer.appendChild(dropdownMenu);
    
        // Append the group container to the menu content
        const menuContent = document.getElementById('menu-content');
        menuContent.appendChild(groupContainer);
      }
      buttonsAdded.current = true;
    });
    
    // Clean up on unmount
    return () => {
      map.remove();
    };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect(()=>{
      mapRef.current.on("mousedown", (e) => {
        console.log(mapRef.current)
        const features = mapRef.current.queryRenderedFeatures(e.point);
        if (features.length ) {
         
          const feature = features[0];
          try{
          console.log(feature.layer.metadata.desc +" Clicked Record")
          setSelectedObject(feature.layer.metadata.desc +" Clicked Record     ")
          }
          catch {
            console.log(feature,"undefined")
            setSelectedObject(" Undefined Clicked Record     ")
          }
  
          if (typeof mapRef.current.getSource('selected_item') !== "undefined"  ){   
                console.log("remove")  
                console.log(mapRef.current)   
                try{
                  mapRef.current.removeLayer('selected_item')
                  mapRef.current.removeSource('selected_item');   
                }
                catch{
                  console.log("no layer to remove")
                }
          }
         
          if (highlightFeatures===true){
          
            const feature_styling=feature.layer.paint
            for (const a_key in feature_styling){
              if (a_key.indexOf("color")!==-1){
                feature_styling[a_key]="#f55e00"
              }
              if (a_key.indexOf("fill-extrusion-height")!==-1){
                feature_styling[a_key]=feature_styling[a_key]+0.1
              }
            
            }
            mapRef.current.addSource('selected_item', {
                "type":"geojson",
                "data": feature.toJSON()
            });
            mapRef.current.addLayer({
                "id": "selected_item",
                "type": feature.layer.type,
                "source": "selected_item",
             
                'paint':feature_styling
            });
          }
          
  
          // Update selected feature
          handleFeatureClick(feature);
        }
      });
    },[highlightFeatures]
    )

  return (
    <div>
      <div id='map-container' className='map-container' ref={mapContainerRef} />
      {selectedFeature && (
        <div id="data-viewer-container">
          <DataViewer feature={selectedFeature} onClose={handleCloseDataViewer} table_name={select_object_table} />
        </div>
      )}
      <div className="map-overlay2"  id="legend-description" ></div>
      <div className="map-overlay" id="legend" ></div>
      <div id='data-table'>
          {dataTableData.length > 0 && (
            <DataTable  data={dataTableData} onClose={handleCloseDataTable} table_name={dataTableName} onRowSelect={handleRowSelect} onFilterApplied={handleFilterApplied}/>
          )}
      </div>
      

      <button className="hamburger-button" onClick={toggleMenu}>
        &#9776; {/* Unicode for the hamburger icon */}
      </button>
      <nav id="menu" className={isOpen ? "open" : ""}>
        <div id='menu-content' className="menu-content">
          <button className={`toggleHighlighting ${highlightFeatures ? 'active' : 'inactive'}`} onClick={toggleHighlighting}>
            Toggle Highlighting
          </button>
          <button className={`toggleLegend ${showLegend ? 'active' : 'inactive'}`} onClick={toggleLegend}>
            Toggle Legend
          </button>
          {/* Your menu items go here */}
        </div>
        {/*
        <div class="map-overlay-inner">
            <fieldset id='layer_set'>
                <label>Select layer to style</label>
                <select id="layer" name="layer">
            
                </select>
            </fieldset>
            <fieldset>
                <label>Select field to style</label>
                <select id="field" name="field">
            
                </select>
            </fieldset>
            <fieldset>
                <label>Choose a color</label>
                <div id="swatches"></div>
            </fieldset>
          </div> */}
          
      </nav>

    </div>
  );
};

export default Map;
