import React, { useState, useRef, useMemo } from 'react';
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import { ModuleRegistry, CsvExportModule } from 'ag-grid-enterprise';
import { SideBarModule, MenuModule } from 'ag-grid-enterprise';
ModuleRegistry.registerModules([CsvExportModule, SideBarModule, MenuModule]);

const generateColumnDefs = (data) => {
  if (data.length === 0) {
    return [];
  }

  // Create a Set to hold all unique keys
  const allKeys = new Set();

  // Iterate through all records and add their keys to the Set
  data.forEach((record) => {
    Object.keys(record).forEach((key) => {
      allKeys.add(key);
    });
  });

  // Convert the Set of unique keys into an array of column definitions
  const columnDefs = Array.from(allKeys).map((key) => ({
    headerName: key,
    field: key,
    sortable: true,
    flex: 0,
    resizable:true,
    
  }));

  return columnDefs;
};

const DataTable = ({ data, onClose, table_name, onRowSelect, onFilterApplied }) => {
  const isColumnNumeric = (columnName) => {
    return data.every((row) => !isNaN(row[columnName]));
  };
  
  const gridRef = useRef();
  const [rowData] = useState(data);


  const columnDefs = useMemo(() => {
    return generateColumnDefs(data).map((colDef) => {
      const uniqueValues = new Set(data.map((row) => row[colDef.field]));
      const numberOfUniqueValues = uniqueValues.size;

      if (numberOfUniqueValues < 100) {
        colDef.filter = 'agSetColumnFilter';
      } else if (isColumnNumeric(colDef.field)) {
        colDef.filter = 'agNumberColumnFilter';
      } else {
        colDef.filter = 'agTextColumnFilter';
      }

      colDef.filterParams = {
        textCustomComparator: function (filter, value, filterText) {
          // Custom text filter logic if needed
        },
        // Set filterParams based on the filter type
      };

      return colDef;
    });
  }, [data]);

 

  const handleRowClick = (record) => {
    if (onRowSelect) {
      onRowSelect(record);
    }
  };

  const handleFilterChanged = () => {
    if (onFilterApplied) {
      const filterData = gridRef.current.api.getFilterModel();
      onFilterApplied(filterData);
    }
  };
  
  const autoSizeAllColumns = () => {
    console.log(gridRef)
    gridRef.current.columnApi.autoSizeAllColumns();
  };



  const test={
    statusPanels: [
        {
            statusPanel: 'agTotalAndFilteredRowCountComponent',
            align: 'left',
        }
    ]
  }


  return (
    <div className="data-viewer">
      <div className="header">
        <button className="close-button" onClick={onClose}>
          X
        </button>
        <h3>{table_name}</h3>
      </div>

      <div className="ag-theme-balham" style={{ height: '200px', width: '100%' }}>
        <AgGridReact
          rowData={rowData}
          ref={gridRef}
          columnDefs={columnDefs}
          onFilterChanged={handleFilterChanged}
          onCellClicked={handleRowClick}
          onFirstDataRendered={autoSizeAllColumns}
          animateRows={true}
          rowSelection="multiple"

          alwaysShowHorizontalScroll={true}
          enableFilter={true}
          statusBar={test}
          enableRangeSelection= {true}
          
          
        ></AgGridReact>
      </div>
    </div>
  );
};

export default DataTable;


/*
<div className="vertical-scroll">
<div className="table-container">
  <table>
    <thead>
      <tr>
        {keys.map((key) => (
          <th
            key={key}
            onClick={() => toggleSort(key)}
            className={sortBy.key === key ? "sorted" : ""}
          >
            {key}{" "}
            {sortBy.key === key && (sortBy.ascending ? "▲" : "▼")}
          </th>
        ))}
      </tr>
    </thead>
    <tbody>
      {sortedData.map((record, index) => (
        <tr key={index} onClick={() => handleRowClick(record)}>
          {keys.map((key) => (
            <td key={key}>{formatValue(record[key])}</td>
          ))}
        </tr>
      ))}
    </tbody>
  </table>
</div>
</div>*/