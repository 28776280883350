import React from "react";
import "./DataViewer.css";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";


const generateColumnDefs = (data) => {
  if (data.length === 0) {
    return [];
  }

  return Object.keys(data[0]).map((key) => ({
    headerName: key,
    field: key,
    resizable:true
  }));
};

const DataViewer = ({ feature , onClose,table_name }) => {
  const properties = feature.properties;
  const columns=generateColumnDefs([properties])

  console.log(columns,properties)



 

  return (
    <div className="data-viewer">
       <div className="header">
      <button className="close-button" onClick={onClose}>
        X 
        
      </button>
      <h3>{table_name}</h3>
      </div>
       <div className="ag-theme-alpine" style={{ height: '100px', width: '100%' }}>
      <AgGridReact rowData={[properties]} 
        columnDefs={columns}
        animateRows={true} // Optional - set to 'true' to have rows animate when sorted
        rowSelection='multiple' ></AgGridReact>
    </div>
    </div>
  );
};

export default DataViewer;
