import * as THREE from 'three';
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader';
import { DRACOLoader } from 'three/addons/loaders/DRACOLoader';
import mapboxgl from 'mapbox-gl';

// Create a function to add a 3D model to the map
export default function add3DModelToMap (map, layerId, modelPath, modelPosition, modelRotation, modelAltitude, modelScale)  {
  const modelAsMercatorCoordinate = mapboxgl.MercatorCoordinate.fromLngLat(
    modelPosition,
    modelAltitude
  );

  const modelTransform = {
    translateX: modelAsMercatorCoordinate.x,
    translateY: modelAsMercatorCoordinate.y,
    translateZ: modelAsMercatorCoordinate.z,
    rotateX: modelRotation[0],
    rotateY: modelRotation[1],
    rotateZ: modelRotation[2],
    scale: modelAsMercatorCoordinate.meterInMercatorCoordinateUnits() * modelScale,
  };

  const customLayer = {
    id: layerId, // Pass the layerId here
    type: 'custom',
    renderingMode: '3d',
    onAdd: function (map, gl) {
      this.camera = new THREE.Camera();
      this.scene = new THREE.Scene();

      // Create lights (directional and ambient) here as in your original code
      const directionalLight = new THREE.DirectionalLight(0xffffff);
        directionalLight.position.set(0, -53, 100).normalize();
        this.scene.add(directionalLight);

        const light = new THREE.AmbientLight( 0xffffff )
        this.scene.add(light)
        const directionalLight2 = new THREE.DirectionalLight(0xffffff);
        directionalLight2.position.set(50, 53, -100).normalize();
        this.scene.add(directionalLight2);

      const dracoLoader = new DRACOLoader();
      dracoLoader.setDecoderPath('https://www.gstatic.com/draco/v1/decoders/'); // Replace with the path to your Draco decoder

      const gltfLoader = new GLTFLoader();
      gltfLoader.setDRACOLoader(dracoLoader);

      gltfLoader.load(
        modelPath,
        (gltf) => {
          this.scene.add(gltf.scene);
          const model = gltf.scene;

          // Apply the model transform
         

          model.scale.set(modelScale, modelScale, modelScale);
        }
      );
      this.map = map;
        
        // use the Mapbox GL JS map canvas for three.js
        this.renderer = new THREE.WebGLRenderer({
          canvas: map.getCanvas(),
          context: gl,
          antialias: true
        });
        
        this.renderer.autoClear = false;
        },
        render: function (gl, matrix) {
          const rotationX = new THREE.Matrix4().makeRotationAxis(
            new THREE.Vector3(1, 0, 0),
            modelTransform.rotateX
          );
          const rotationY = new THREE.Matrix4().makeRotationAxis(
            new THREE.Vector3(0, 1, 0),
            modelTransform.rotateY
          );
          const rotationZ = new THREE.Matrix4().makeRotationAxis(
            new THREE.Vector3(0, 0, 1),
            modelTransform.rotateZ
          );
        
          const m = new THREE.Matrix4().fromArray(matrix);
          const l = new THREE.Matrix4()
          .makeTranslation(
            modelTransform.translateX,
            modelTransform.translateY,
            modelTransform.translateZ
          )
          .scale(
            new THREE.Vector3(
            modelTransform.scale,
            -modelTransform.scale,
            modelTransform.scale
           )
          )
          .multiply(rotationX)
          .multiply(rotationY)
          .multiply(rotationZ);
          
          this.camera.projectionMatrix = m.multiply(l);
          this.renderer.resetState();
          this.renderer.render(this.scene, this.camera);
          this.map.triggerRepaint();
        }
  };

  // Add the custom layer to the map


  // Return the custom layer for further manipulation if needed
  return customLayer;
};