import * as THREE from 'three';
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader';
import { DRACOLoader } from 'three/addons/loaders/DRACOLoader';
import mapboxgl from 'mapbox-gl';

export const home = () => {
    const modelOrigin = [  -9.088193479952746,53.26828941393028];
    const modelAltitude = 0;
    const modelRotate = [Math.PI / 2,-325*((-Math.PI)/360),0];

    const modelAsMercatorCoordinate = mapboxgl.MercatorCoordinate.fromLngLat(
        modelOrigin,
        modelAltitude
    );

    const modelTransform = {
      translateX: modelAsMercatorCoordinate.x,
      translateY: modelAsMercatorCoordinate.y,
      translateZ: modelAsMercatorCoordinate.z,
      rotateX: modelRotate[0],
      rotateY: modelRotate[1],
      rotateZ: modelRotate[2],
      /* Since the 3D model is in real world meters, a scale transform needs to be
       * applied since the CustomLayerInterface expects units in MercatorCoordinates.
       */
      scale: modelAsMercatorCoordinate.meterInMercatorCoordinateUnits()
  };




const customLayer = {
        id: '3d-garden',
        type: 'custom',
 
        renderingMode: '3d',
        onAdd: function (map, gl) {
        this.camera = new THREE.Camera();
        this.scene = new THREE.Scene();
        
        // create two three.js lights to illuminate the model
        const directionalLight = new THREE.DirectionalLight(0xffffff);
        directionalLight.position.set(0, -53, 100).normalize();
        this.scene.add(directionalLight);

        const light = new THREE.AmbientLight( 0xffffff )
        this.scene.add(light)
        const directionalLight2 = new THREE.DirectionalLight(0xffffff);
        directionalLight2.position.set(50, 53, -100).normalize();
        this.scene.add(directionalLight2);
        
        // use the three.js GLTF loader to add the 3D model to the three.js scene
        const dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath('https://www.gstatic.com/draco/v1/decoders/'); // Replace with the path to your Draco decoder

        const gltfLoader = new GLTFLoader();
        gltfLoader.setDRACOLoader(dracoLoader);

        gltfLoader.load(
          './12_carraig_ard_2.glb',
          (gltf) => {
            
          this.scene.add(gltf.scene);
          const model = gltf.scene;


          // Scale the model down by a factor of 2 in all three dimensions
          model.scale.set(1, 1, 1);
          }
        );
        this.map = map;
        
        // use the Mapbox GL JS map canvas for three.js
        this.renderer = new THREE.WebGLRenderer({
          canvas: map.getCanvas(),
          context: gl,
          antialias: true
        });
        
        this.renderer.autoClear = false;
        },
        render: function (gl, matrix) {
          const rotationX = new THREE.Matrix4().makeRotationAxis(
            new THREE.Vector3(1, 0, 0),
            modelTransform.rotateX
          );
          const rotationY = new THREE.Matrix4().makeRotationAxis(
            new THREE.Vector3(0, 1, 0),
            modelTransform.rotateY
          );
          const rotationZ = new THREE.Matrix4().makeRotationAxis(
            new THREE.Vector3(0, 0, 1),
            modelTransform.rotateZ
          );
        
          const m = new THREE.Matrix4().fromArray(matrix);
          const l = new THREE.Matrix4()
          .makeTranslation(
            modelTransform.translateX,
            modelTransform.translateY,
            modelTransform.translateZ
          )
          .scale(
            new THREE.Vector3(
            modelTransform.scale,
            -modelTransform.scale,
            modelTransform.scale
           )
          )
          .multiply(rotationX)
          .multiply(rotationY)
          .multiply(rotationZ);
          
          this.camera.projectionMatrix = m.multiply(l);
          this.renderer.resetState();
          this.renderer.render(this.scene, this.camera);
          this.map.triggerRepaint();
        }
      };


  return customLayer;
};
